<template>
  <b-overlay
    v-if="$can('read', 'notas')"
    :show="cargando"
    spinner-variant="primary"
    :style="cargando ? 'margin-top: 300px !important; margin-bottom: 600px !important;' : ''"
    variant="semi-dark"
  >
    <!-- Crear -->
    <evaluaciones-formativas-create
      :asignaturas.sync="asignaturas"
      :asignaturaIndex.sync="asignaturaIndex"
      :id_periodo_activo.sync="id_periodo_activo"
      @pintarEvaluacion="pintarEvaluacion"
    />
    <evaluaciones-formativas-update
      :evaluacion.sync="evaluacion"
      @pintarEvaluacion="pintarEvaluacion"
    />

    <b-alert
      v-if="bloquesAlert"
      variant="info"
      show
      class="text-center mt-25 mr-25 ml-25 pb-2 pt-2"
    >
      {{alertMsg}}
    </b-alert>
    <b-table
      v-else-if="!cargando"
      small
      hover
      noCollapse
      bordered
      responsive
      sticky-header
      class="mt-0 mb-2 rounded"
      :style="cargando ? 'min-height: 625px !important;' : 'min-height: 625px !important; margin-top: -10px !important;'"
      :fields="!cargando ? fields : null"
      :items="items"
      @head-clicked="headTitulo"
    >
      <!-- Cargando -->
      <template #table-busy>
        <div class="text-center text-danger my-1 mt-5">
          <spinner />
        </div>
      </template>

      <template

        #cell()="data"
      >
        <div
          v-if="data.field.key == 'num_lista'"
        >
          <estudiante-retirado
            :rut="data.item.rut"
            :data.sync="data.item.orden.toString()"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :fecha_ingreso.sync="data.item.fecha_ingreso"
            :genero.sync="data.item.genero"
            :mayus="false"
          />
        </div>

        <div
          class="divEstudiante"
          v-else-if="data.field.key == 'nombre_completo'"
        >
          <estudiante-retirado
            :rut="data.item.rut"
            :data.sync="data.item.nombre_completo"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :fecha_ingreso.sync="data.item.fecha_ingreso"
            :genero.sync="data.item.genero"
            :mayus="true"
          />
        </div>

        <nota-alumno-evaluacion
          v-else-if="data.field.evaluacion"
          :item.sync="data.item"
          :items.sync="items"
          :field.sync="data.field"
          :fields.sync="fields"
          :fecha_retiro.sync="data.item.fecha_retiro"
          @updatePromedio="updatePromedio"
        />

        <div
          v-else-if="data.field.key == 'promedio_primer'"
        >
          <spinner v-if="cargandoNotas" size="small"/>
          <div v-else>
            {{ data.item.promedio_periodo === 0 ? '.' : formatNota(data.item.promedio_periodo) }}
          </div>
        </div>

        <div
          v-else-if="data.field.key == 'promedio_segundo'"
        >
          <spinner v-if="cargandoNotas" size="small"/>
          <div v-else>
            {{ data.item.promedio_periodo === 0 ? '.' : formatNota(data.item.promedio_periodo) }}
          </div>
        </div>

        <div
          v-else-if="data.field.key == 'promedio_tercero'"
        >
          <spinner v-if="cargandoNotas" size="small"/>
          <div v-else>
            {{ data.item.promedio_periodo === 0 ? '.' : formatNota(data.item.promedio_periodo) }}
          </div>
        </div>

        <div
          v-else-if="data.field.key == 'promedio_final'"
        >
          <spinner v-if="cargandoNotas" size="small"/>
          <div v-else>
            {{ data.item.promedio_final_asignatura === 0 ? '.' : formatNota(data.item.promedio_final_asignatura) }}
          </div>
        </div>

      </template>

    </b-table>
  </b-overlay>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BButtonGroup, BAvatar, BOverlay, BCard, BTab, BTabs,
  BFormRadioGroup, BTable, BCardText, BAlert,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import { mapGetters, mapActions } from 'vuex'

// FORMATOS
import { rut } from '@core/mixins/ui/rut'
import { formatos } from '@core/mixins/ui/formatos'

// COMPONENTES RECICLADOS
import spinner from '../../../components/spinner.vue'

// COMPONENTES HIJOS
import NotaAlumnoEvaluacion from './components/NotaAlumnoEvaluacion.vue'
import EvaluacionesFormativasCreate from './EvaluacionesFormativas/EvaluacionesCreate.vue'
import EvaluacionesFormativasUpdate from './EvaluacionesFormativas/EvaluacionesUpdate.vue'
import estudianteRetirado from '../../../components/List/estudianteRetirado.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BAlert,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BButtonGroup,
    BFormRadioGroup,
    BAvatar,
    BOverlay,
    BCard,
    BTab,
    BTabs,
    BTable,
    BCardText,

    // COMPONENTES RECICLADOS
    spinner,

    // COMPONENTES HIJOS
    NotaAlumnoEvaluacion,
    EvaluacionesFormativasCreate,
    EvaluacionesFormativasUpdate,
    estudianteRetirado,
  },
  mixins: [rut, formatos],
  directives: {
    Ripple,
  },
  data() {
    return {
      cargando: true,
      cargandoNotas: true,
      items: [],
      evaluacion: {},
      fields: [],

      bloquesAlert: false,
      alertMsg: 'El curso no tiene Estudiantes Matriculados.',

      impresionEvaluaciones: [],

      fieldsEstudiantes: [
        {
          key: 'num_lista',
          label: '#',
          variant: 'primary',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '1% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre_completo',
          label: 'Estudiantes',
          variant: 'light',
          stickyColumn: true,
          thStyle: {
            width: '5% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],

      fieldsSemestre: [
        {
          key: 'promedio_primer',
          label: 'P1',
          headerTitle: 'Promedio 1er Semestre',
          tdClass: 'text-center',
          variant: 'primary',
          thStyle: {
            'text-align': 'center',
            width: '5% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'promedio_segundo',
          label: 'P2',
          headerTitle: 'Promedio 2do Semestre',
          tdClass: 'text-center',
          variant: 'primary',
          thStyle: {
            'text-align': 'center',
            width: '5% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],

      fieldsTrimestre: [
        {
          key: 'promedio_primer',
          label: 'P1',
          headerTitle: 'Promedio 1er Trimestre',
          tdClass: 'text-center',
          variant: 'primary',
          thStyle: {
            'text-align': 'center',
            width: '5% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'promedio_segundo',
          label: 'P2',
          headerTitle: 'Promedio 2do Trimestre',
          tdClass: 'text-center',
          variant: 'primary',
          thStyle: {
            'text-align': 'center',
            width: '5% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'promedio_tercer',
          label: 'P3',
          headerTitle: 'Promedio 3er Trimestre',
          tdClass: 'text-center',
          variant: 'primary',
          thStyle: {
            'text-align': 'center',
            width: '5% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],

      fieldPromedioFinal: [
        {
          key: 'promedio_final',
          label: 'PF',
          tdClass: 'text-center',
          variant: 'primary',
          headerTitle: 'Promedio Final',
          thStyle: {
            'text-align': 'center',
            width: '5% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getNotas: 'notas/getNotas',
      getPeriodosEstablecimiento: 'periodos/getPeriodosEstablecimiento',
      getEvaluaciones: 'evaluaciones/getEvaluacionesSumativas',
    }),
  },
  watch: {
    getEvaluaciones(getEvaluaciones) {
      this.cargarNotas(this.id_asignatura)
      this.cargarFields(getEvaluaciones)
    },
    getNotas(val) {
      if (val.status !== 'error') {
        this.setNotasEstudiantes(this.getNotas)
        this.cargandoNotas = false
      } else {
        this.bloquesAlert = true
      }
      this.cargando = false
    },
    id_asignatura(id_asignatura) {
      this.cargandoNotas = true
      this.cargarEvaluaciones(this.id_asignatura)
      this.cargarNotas(id_asignatura)
    },
    id_periodo_activo(id_periodo_activo) {
      this.cargarEvaluaciones(this.id_asignatura)
      this.cargarNotas(this.id_asignatura)
    },
  },
  props: {
    id_asignatura: {
      type: Number,
      required: true,
    },
    asignaturas: {
      type: Array,
      required: true,
    },
    asignaturaIndex: {
      type: Number,
      required: true,
    },
    id_periodo_activo: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.cargarEvaluaciones(this.id_asignatura)
    this.cargarNotas(this.id_asignatura)
  },
  methods: {
    ...mapActions({
      fetchEvaluaciones: 'evaluaciones/fetchEvaluacionesSumativas',
      fetchNotas: 'notas/fetchNotasSumativa',
    }),
    cargarEvaluaciones(id_asignatura) {
      this.fetchEvaluaciones(id_asignatura)
    },
    cargarNotas(id_asignatura) {
      this.cargandoNotas = true
      this.fetchNotas(id_asignatura)
    },
    cargarFields(getEvaluaciones) {
      this.fields = []
      this.cargarFieldsEstudiantes()
      if (getEvaluaciones.message !== "Registro no existe") {
        this.cargarFieldEvaluaciones(getEvaluaciones)
      }
      // this.cargarFieldsPromedios(this.getPeriodoNotas())
      // this.cargarFieldPromedioFinal()
    },
    cargarFieldsEstudiantes() {
      this.fieldsEstudiantes.forEach(field => {
        this.fields.push(field)
      })
    },
    cargarFieldEvaluaciones(getEvaluaciones) {
      let evaluaciones = this.getEvaluaciones.filter(e => e.id_periodo === this.id_periodo_activo)
      for (let i = 0; i < evaluaciones.length; i++) {
        const evaluacion = evaluaciones[i];
        if (evaluacion.id !== 0) {
          const numNota = i+1
          const nombre_evaluacion  = evaluacion.nombre.length > 15
            ? evaluacion.nombre.substring(0, 15) + '...'
            : evaluacion.nombre;
          const nombre_evaluacion_principal  = evaluacion.nombre_evaluacion_principal.length > 15
            ? evaluacion.nombre_evaluacion_principal.substring(0, 15) + '...'
            : evaluacion.nombre_evaluacion_principal;
          const label = `N${numNota}
${this.formatoDiaMes(evaluacion.fecha)}
${nombre_evaluacion}
${nombre_evaluacion_principal}`

          const descripcion = evaluacion.descripcion === ''
            ? '...'
            : evaluacion.descripcion
          const headerTitle = `Evaluación Formativa
* Fecha: ${this.formatDiaMesCompleto(evaluacion.fecha)}
* Nombre: ${evaluacion.nombre}
* Descripción: ${descripcion}

Evaluación Principal
* Nombre evaluación: ${evaluacion.nombre_evaluacion_principal}`

          // ☜ ✍ manito
          this.fields.push(
            {
              key: 'nota_'+numNota,
              label,
              numNota,
              tdClass: 'text-center',
              headerTitle,
              evaluacion: true,
              id_evaluacion: evaluacion.id,
              evaluacion: evaluacion,
              fecha_evaluacion: evaluacion.fecha,
              variant: 'light',
              tdStyle: {
                width: '10% !important',
              },
              thStyle: {
                'cursor' : 'pointer',
                'text-align': 'center',
                width: '5% !important',
                display: 'table-cell',
                'vertical-align': 'middle',
              },
            },
          )
        } else {
          const numNota = i+1
          const label = evaluacion.nombre_asignatura
          const headerTitle =
          `* Asignatura: ${evaluacion.nombre_asignatura}`

          // ☜ ✍ manito
          this.fields.push(
            {
              key: 'nota',
              label,
              tdClass: 'text-center',
              headerTitle,
              evaluacion: true,
              id_evaluacion: evaluacion.id,
              variant: 'info',
              tdStyle: {
                width: '10% !important',
              },
              thStyle: {
                'cursor' : 'pointer',
                'text-align': 'center',
                width: '5% !important',
                display: 'table-cell',
                'vertical-align': 'middle',
              },
            },
          )
        }
      }
    },

    cargarFieldsPromedios(periodo) {
      // ver si es semestal o trimestral y desplegar promedios
      if (this.getPeriodosEstablecimiento.length === 2) {
        this.fields.push(this.fieldsSemestre[periodo])
      } else if (this.getPeriodosEstablecimiento.length === 3) {
        this.fields.push(this.fieldsTrimestre[periodo])
      }
    },
    cargarFieldPromedioFinal() {
      this.fieldPromedioFinal.forEach(field => {
        this.fields.push(field)
      })
    },

    getPeriodoNotas() {
      const periodo = this.getPeriodosEstablecimiento.find(pa => pa.id === this.id_periodo_activo)
      let response
      if (periodo.nombre === '1er Semestre') {
        response = 0
      } else if (periodo.nombre === '2do Semestre') {
        response = 1
      } else if (periodo.nombre === '1er Trimestre') {
        response = 0
      } else if (periodo.nombre === '2do Trimestre') {
        response = 1
      } else if (periodo.nombre === '3er Trimestre') {
        response = 2
      }
      return response
    },

    setNotasEstudiantes(estudiantes) {
      const periodoNotas = this.getPeriodoNotas()
      for (let i = 0; i < estudiantes.length; i++) {
        let estudiante = estudiantes[i]
        estudiante['nombre_completo'] = `${estudiante.primer_apellido}
                  ${estudiante.segundo_apellido}
                  ${estudiante.nombre}
                  ${estudiante.segundo_nombre !== null
                    ? estudiante.segundo_nombre
                    : ''}
                  `
        estudiante['fecha_retiro'] = estudiante.fecha_retiro
        estudiante['notas'] =  estudiantes[i][periodoNotas]['notas']
        estudiante['promedio_periodo'] =  estudiantes[i][periodoNotas]['promedio']
        estudiante['nombre_periodo'] =  estudiantes[i][periodoNotas]['nombre_periodo']
        estudiante['id_periodo'] =  estudiantes[i][periodoNotas]['id_periodo']
      }
      this.items = []
      this.items = estudiantes
    },

    updatePromedio (datos) {
      const datosEstudiante = datos.datos_estudiante
      const alumnoNotas = this.items.find(i => i.id_persona_rol_alumno === datos.id_persona_rol_alumno)
      if (datos.tipo === 'create') {
        alumnoNotas.notas.push({
          id_evaluacion_sumativa: datos.id_evaluacion,
          id_evaluacion: datos.id,
          id_nota: datos.id_nota,
          id_periodo: this.id_periodo_activo,
          nota: datos.nota,
        })
        const periodoNotas = this.getPeriodoNotas()
        alumnoNotas[periodoNotas].notas.push({
          id_evaluacion_sumativa: datos.id_evaluacion_sumativa,
          id_evaluacion: datos.id_evaluacion,
          id_nota: datos.id_nota,
          id_periodo: this.id_periodo_activo,
          nota: datos.nota,
        })
      }
      alumnoNotas.promedio_final_asignatura = datosEstudiante.promedio_final_asignatura
      alumnoNotas.promedio_periodo = datosEstudiante[this.getPeriodoNotas()].promedio
    },

    pintarEvaluacion(evaluacion) {
      const indexField = this.fields.findIndex(f => f.id_evaluacion === evaluacion.id)
      this.fields[indexField].variant = 'success'
      setTimeout(() => {
        this.fields[indexField].variant = 'light'
      }, 1500);
    },

    headTitulo(key, field) {
      if (field.evaluacion && this.$can('read', 'evaluaciones')) {
        const evaluacion = this.getEvaluaciones.find(e => e.id === field.id_evaluacion)
        this.evaluacion = evaluacion
        this.$bvModal.show('evaluacion-formativa-update')
      }
    },
  },
}
</script>
