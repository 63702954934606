var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('b-form',{ref:"form",style:({height: _vm.trHeight}),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},[_vm._l((_vm.items),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Estrategias de participación","label-for":"participacion"}},[_c('b',[_vm._v(_vm._s(item.participacion))])])],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Descripción","label-for":"descripcion"}},[_c('b-form-textarea',{attrs:{"id":"descripcion","type":"text","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa una descripción'
              : 'Sin información',"plaintext":!_vm.$can('update', _vm.nombre_permiso),"row":"2"},model:{value:(item.descripcion),callback:function ($$v) {_vm.$set(item, "descripcion", $$v)},expression:"item.descripcion"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Seguimiento","label-for":"seguimiento"}},[_c('b-form-textarea',{attrs:{"id":"seguimiento","type":"text","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa el seguimiento'
              : 'Sin información',"plaintext":!_vm.$can('update', _vm.nombre_permiso),"row":"2"},model:{value:(item.seguimiento),callback:function ($$v) {_vm.$set(item, "seguimiento", $$v)},expression:"item.seguimiento"}})],1)],1),_c('b-col',{attrs:{"md":item.participacion === 'Otra acción'
          ? 3
          : 4}},[_c('b-form-group',{attrs:{"label":"Evaluación","label-for":"evaluacion"}},[_c('b-form-textarea',{attrs:{"id":"evaluacion","type":"text","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa la evaluación'
              : 'Sin información',"plaintext":!_vm.$can('update', _vm.nombre_permiso),"row":"2"},model:{value:(item.evaluacion),callback:function ($$v) {_vm.$set(item, "evaluacion", $$v)},expression:"item.evaluacion"}})],1)],1),(index > 2)?_c('b-col',{attrs:{"lg":"1","md":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-3 float-left btn btn-sm",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeAccion(index, item.id_estrategia_detalle)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span')],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('colLinea')],1)],1)}),(_vm.$can('create', _vm.nombre_permiso))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"disabled":_vm.items.length >= 9,"variant":"outline-primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Agregar acción")])],1):_vm._e(),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-form-group',{attrs:{"label":"Observaciones","label-for":"observaciones"}},[_c('b-form-textarea',{attrs:{"id":"observaciones","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa las observaciones'
              : 'Sin información',"plaintext":!_vm.$can('update', _vm.nombre_permiso),"rows":"4","state":_vm.v$.estrategia.observaciones.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.estrategia.observaciones.$touch($event)}},model:{value:(_vm.estrategia.observaciones),callback:function ($$v) {_vm.$set(_vm.estrategia, "observaciones", $$v)},expression:"estrategia.observaciones"}}),(_vm.v$.estrategia.observaciones.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"observacionesInfo"}},_vm._l((_vm.v$.estrategia.observaciones.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('btnSubmit',{staticClass:"float-right",attrs:{"variant":"primary","disabled":this.v$.estrategia.$errors.length > 0,"modulo":_vm.nombre_permiso,"btnText":_vm.btnSubmit},on:{"processBtn":_vm.submitOption}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }