<template>
  <evaluaciones-form
    nombreModal="modal-evaluacion-acumulativa-create"
    title="Crear evaluación acumulativa"
    :asignaturas="asignaturas"
    :evaluacion.sync="data"
    :id_periodo_activo.sync="id_periodo_activo"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import EvaluacionesForm from './EvaluacionesForm.vue'

export default {
  components: {
    // COMPONENTES
    EvaluacionesForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        fecha: null,
        nombre: '',
        descripcion: '',
        orden: 1,
        id_evaluacion: null,
        id_asignatura: null,
        id_periodo: null,
      },
    }
  },
  props: {
    asignaturas: {
      type: Array,
      required: true,
    },
    asignaturaIndex: {
      type: Number,
      required: true,
    },
    id_periodo_activo: {
      type: Number,
      required: true,
    },
  },
  watch: {
    asignaturaIndex(asignaturaIndex){
      this.setAsignatura(asignaturaIndex)
    },
    asignaturas(asignaturas){
      this.setAsignatura(this.asignaturaIndex)
    },
    id_periodo_activo(asignaturas){
      this.setAsignatura(this.asignaturaIndex)
    },
  },
  mounted() {
    this.setAsignatura(this.asignaturaIndex)
  },
  methods: {
    ...mapActions({
      createEvaluacion: 'evaluaciones/addEvaluacionSumativa',
      fetchEvaluaciones: 'evaluaciones/fetchEvaluacionesSumativas',
    }),
    setAsignatura(asignaturaIndex) {
      this.data.id_asignatura = this.asignaturas[asignaturaIndex-1].id
      this.data.id_periodo = this.id_periodo_activo
    },
    agregar(evaluacion) {
      this.createEvaluacion(evaluacion).then(() => {
        const statusEvaluaciones = store.state.evaluaciones.status

        if (statusEvaluaciones === 'success') {
          const id_evaluacion = store.state.evaluaciones.id_evaluacion
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Evaluación guardada 👍',
              icon: 'CheckIcon',
              text: 'La evaluación sumativa fue ingresada con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 6000,
          })
          this.$bvModal.hide('modal-evaluacion-acumulativa-create')
          this.fetchEvaluaciones(evaluacion.id_asignatura).then(() => {
            const datos = {
              id: id_evaluacion,
            }
            this.$emit('pintarEvaluacion', datos)
          })
          this.resetData()
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.evaluaciones.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
    resetData() {
      this.data.fecha = null
      this.data.tipo = 1
      this.data.nombre = ''
      this.data.descripcion = ''
      this.data.orden = 1
      this.data.parcial = 1
      this.data.porcentaje = null
      // id_asignatura = null
    },
  },
}
</script>
