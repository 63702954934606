<template>
  <div
    class="text-center"
    style="
      margin-top: -4px; padding-top: 4px;
      margin-left: -6px; padding-left: 6px;
      margin-right: -6px; padding-right: 6px;
      margin-bottom: -3px; padding-bottom: 3px;
      min-width: 140px !important;
    "
  >
    <spinner
      v-if="showSpinner"
      size="small"
    />

    <b-row>
      <!-- CHECK ASISTENCIA -->
      <b-col
        cols="1"
        sm="1"
        md="1"
        lg="1"
        class="mr-0"
      >
        <b-form-checkbox
          v-show="asistencia"
          v-model="asistencia"
          size="md"
          title="Presente"
          :class="`custom-control-success float-left mt-25 ${classBox}`"
          :disabled="disableAsistencia"
          :state="asistencia"
          @change="chkAsistencia(false)"
        />
        <span
          v-show="!asistencia"
          @click="disableAsistencia === true
            ? ''
            : chkAsistencia(true)"
          class="float-left"
        >

          <feather-icon
            :icon="tipo_asistencia !== 18
              ? 'XSquareIcon'
              : 'MinusSquareIcon'"
            size="21"
            title="Ausente"
            :class="tipo_asistencia !== 18
              ? 'text-danger ml-0 mt-25'
              : 'text-primary ml-0 mt-25'"
            style="margin-left: -2px !important; cursor: initial;"
            :disabled="disableAsistencia"
          />
        </span>
      </b-col>
      <!-- TIPO ASISTENCIA -->
      <b-col
        cols="9"
        lg="9"
        md="9"
        sm="9"
        class="ml-0"
        v-b-tooltip.hover.v-primary
        :title="disableAsistencia ? titleSelect : ''"
      >
        <b-form-select
          :id="`tipo_asistencia_${item.id_persona_rol}-${field.key}`"
          v-model="tipo_asistencia"
          style="min-width: 109% !important"
          class="ml-0"
          :options="optionsTipoAsistencia"
          value-field="id"
          size="sm"
          :title="disableAsistencia ? '' : titleSelect"
          text-field="nombre"
          :disabled="disableAsistencia"
          @change="changeTipoAsistencia()"
        />
        <b-tooltip
          v-if="!disableAsistencia"
          :target="`tipo_asistencia_${item.id_persona_rol}-${field.key}`"
          :delay="delay"
          variant="primary"
        >
          {{titleSelect}}
        </b-tooltip>
      </b-col>

    </b-row>

  </div>
</template>

<script>
import {
  BFormCheckbox, BFormSelect, BCol, BRow, BButton, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// FORMATOS
import { formatos } from '@core/mixins/ui/formatos'
import { compara } from '@core/mixins/ui/compara'

// COMPONENTES RECICLADOS
import spinner from '../../../../../components/spinner.vue'

export default {
  components: {
    BFormCheckbox,
    BFormSelect,
    BCol,
    BRow,
    BButton,
    VBTooltip,
    BTooltip,

    // COMPONENTES RECICLADOS
    spinner,
  },
  data() {
    return {
      delay: { show: 1800 },
      showSpinne: false,
      asistencia: true,
      state: true,
      disableAsistencia: false,
      asignatura: null,
      id_asistencia: null,
      id_bloque: null,
      tipo_asistencia: 1,
      classDiv: null,
      classBox: null,
      titleSelect: null,
      optionsTipoAsistencia: [],
      alumnoRetirado: null,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [formatos, compara],
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: false,
    },
    tipoAsistencia: {
      type: Array,
      required: true,
    },
    asistenciasBloques: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
    }),
    showSpinner() {
      return false
    },
  },
  watch: {
    tipoAsistencia(val) {
      this.setTipoAsistencia(val)
    },
    asistenciasBloques(asistenciasBloques) {
      this.setAsistencias(this.item.bloques, this.field)
    },
    optionsTipoAsistencia(optionsTipoAsistencia) {
      const tipoAsistencia = optionsTipoAsistencia.find(ta => ta.id === this.tipo_asistencia)
      if (typeof tipoAsistencia !== 'undefined') {
        this.titleSelect = tipoAsistencia.nombre
      }
    },
  },
  mounted() {
    this.inicialize()
  },
  methods: {
    async inicialize () {
      await Promise.all([
        this.setAsistencias(this.item.bloques, this.field),
        this.setTipoAsistencia(this.tipoAsistencia)
      ])
    },
    setTipoAsistencia(tipoAsistencia) {
      this.optionsTipoAsistencia = []
      this.optionsTipoAsistencia = tipoAsistencia
    },
    setAsistencias(bloques, field) {
      if (field.bloque) {
        const bloque = bloques.find(n => n.id === Number(field.id_bloque))
        console.log('bloque :', bloque)
        this.id_bloque = field.id_bloque

        this.bloque = typeof bloque !== 'undefined'
          ? bloque.bloque
          : 0

        this.id_bloque = typeof bloque !== 'undefined'
          ? bloque.id
          : null

        if (field.estado_firma === 1 && this.getUser.id_rol !== 17) {
          this.disableAsistencia = true
        } else {
          this.disableAsistencia = this.getUser.id_rol !== 17
            ? this.getUser.id_persona_rol !== bloque.id_persona_rol_docente
            : false
        }

        let tipo = ''
        if (
          typeof bloque.id_tipo_asistencia === 'undefined'
          || bloque.id_tipo_asistencia === null
        ) {
          tipo = 2
          this.tipo_asistencia = 1
        } else {
          this.tipo_asistencia = bloque.id_tipo_asistencia
          tipo = this.tipo_asistencia >= 1 && this.tipo_asistencia <= 5
            ? 2 // presente
            : 1 // ausente
        }

        if (bloque.tipo === null) {
          this.asistencia = true // PRESENTE
        } else {
          this.asistencia = bloque.tipo === 1
            ? false // AUSENTE
            : true // PRESENTE
        }

        //* Ve coloreado solo si son sus asistencias.
        //* bg-primary = guardado
        //* bg-primary = firmado y guardado
        if (!this.disableAsistencia) {
          this.classDiv = typeof bloque.id_asistencia === 'undefined'
            ? null
            : 'bg-light-primary'

          this.classBox = null
          if (typeof bloque.id_asistencia !== 'undefined') {
            this.classBox = this.asistencia
              ? 'custom-control-success'
              : null
          }
        }

        this.asignatura = bloque.nombre_asignatura
        this.setAsistenciaBloque()
      }
    },
    chkAsistencia(asistencia) {
      this.asistencia = asistencia
      if (this.asistencia) { // true = presente
        this.tipo_asistencia = 1
      } else {                // false = ausente
        this.tipo_asistencia = 16
      }
      this.setAsistenciaBloque()
    },
    changeTipoAsistencia(){
      if (this.tipo_asistencia) {
        const asistencia = this.optionsTipoAsistencia.find(ta => ta.id ===  this.tipo_asistencia)
        this.titleSelect = ''
        this.titleSelect = asistencia.nombre

        if (this.tipo_asistencia >= 1 && this.tipo_asistencia <= 5 ) {
          this.asistencia = true // PRESENTE
        } else {
          this.asistencia = false // AUSENTE
        }
        this.setAsistenciaBloque()
      }
    },
    setAsistenciaBloque() {
      const alumno = this.asistenciasBloques.find(ab => ab.id === this.item.id)
      if (typeof alumno !== 'undefined') {
        this.alumnoRetirado = this.comparaFechas(alumno.fecha_retiro, alumno.fecha_ingreso, alumno.fecha)
        const bloque = alumno.bloques.find(b => b.id === this.id_bloque)
        if (this.alumnoRetirado) {
          this.tipo_asistencia = 18
          this.disableAsistencia = true
          this.asistencia = false
        }
        bloque.tipoAsistencia = this.tipo_asistencia
      }
    }
  },
}
</script>

<style lang="scss">
  .custom-control-red {
    border-color: red;
    background-color: red;
  }
</style>