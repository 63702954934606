<template>
    <!-- :style="`margin: -2px !important; background: ${color};`" -->
  <div class="text-center">
    <div
      v-if="tipo_asistencia === '.'"
    >
      <spinner
        v-if="showSpinner"
        size="small"
      />
      <span
        v-if="!field.isWeek"
        variant="secundary"
        v-b-tooltip.hover.v-primary
        title="No tiene asistencias registradas."
        width="50px"
      >
        {{ tipo_asistencia }}
      </span>
      <span
        v-else
        variant="secundary"
      >
      </span>
    </div>
    <b-badge
      v-else
      :variant="title === 'No Matriculado'
          ? 'light-primary'
          : variant"
      v-b-tooltip.hover.v-primary
      :title="title"
    >
      <spinner
        v-if="showSpinner"
        size="small"
        pill
      />

      <feather-icon
        :icon="title === 'No Matriculado'
          ? 'MinusIcon'
          : tipo_asistencia === 'Presente'
            ? 'CheckIcon'
            : 'XIcon'"
        size="14"
      />
    </b-badge>
  </div>
</template>

<script>

import { BBadge, VBTooltip } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// FORMATOS
import { formatos } from '@core/mixins/ui/formatos'

// COMPONENTES RECICLADOS
import spinner from '../../../../../components/spinner.vue'



export default {
  components: {
    BBadge,
    VBTooltip,
    // COMPONENTES RECICLADOS
    spinner,
  },
  data() {
    return {
      showSpinner: false,
      tipo_asistencia: '-',
      title: null,
      variant: null,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [formatos],
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    tipoAsistencia: {
      type: Array,
      required: true,
    },
    modulo: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      getTipoAsistencia: 'asistencias/getTipoAsistencia',
      getAsistenciasMensual: 'asistencias/getAsistenciasMensual',
    }),
  },
  watch: {
    getAsistenciasMensual(val) {
      this.setDias(this.item.dias, this.field)
    }
  },
  mounted() {
    this.setDias(this.item.dias, this.field)
  },
  methods: {
    ...mapActions({
    }),

    setDias(dias, field) {
      if (field.dia) {
        const dia = dias.find(n => n.numeroDia === Number(field.numeroDia))
        const tipo_asistencia = this.getTipoAsistencia.find(ta => ta.id === Number(dia.tipo_asistencia))
        if (typeof tipo_asistencia === 'undefined' || field.isWeek) {
          this.tipo_asistencia = '.'
        } else if (tipo_asistencia.tipo === 1) {
          this.tipo_asistencia = 'Ausente'
          this.title = tipo_asistencia.nombre
          this.variant = 'light-danger'
        } else if (tipo_asistencia.tipo === 2) {
          this.tipo_asistencia = 'Presente'
          this.title = tipo_asistencia.nombre
          this.variant = 'light-success'
        }
      }
    },
  },
}
</script>
