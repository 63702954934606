<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-row
      class="mb-3"
    >
      <b-col
        lg="2"
        md="3"
        sm="3"
        class="my-1"
      >

        <!-- BOTON MOSTRAR -->
        <btnMostrar
          :pageOptions.sync="pageOptions"
          :perPage.sync="perPage"
          :total.sync="items.length"
        />

      </b-col>
      <b-col
        lg="6"
        md="5"
        sm="5"
        class="my-1"
      >

        <!-- FILTRO -->
        <!-- <inputFiltro
          :filter.sync="filter"
        /> -->

      </b-col>
      <b-col
        md="4"
        sm="4"
        class="my-25"
      >

        <!-- modulo="Apoyo" -->
        <!-- CREAR y EDITAR -->
        <apoyosCreate
          submitTitle="Guardar Apoyo"
          title="Registrar apoyo"
          :idCurso.sync="getLibroSelected.id"
        />

        <!-- BOTON CREAR -->
        <btn-crear-modal
          :modulo="nombre_permiso"
          accion="Registrar"
          texto="Apoyo"
          modal="modal-create"
        />

      </b-col>

      <b-col cols="12" style="min-height: 490px !important; margin-top: -16px;">
        <b-table
          striped
          small
          hover
          noCollapse
          bordered
          class="mt-1 rounded"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="cargando"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >

          <!-- Cargando -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <spinner />
            </div>
          </template>

          <!-- col HORAS_AULA -->
          <template #cell(horas_aula)="data">
            {{ data.item.horas_aula }} hrs
          </template>
          <!-- col HORAS_FUERA -->
          <template #cell(horas_fuera)="data">
            {{ data.item.horas_fuera }} hrs
          </template>

          <!-- col ACCIONES -->
          <template #cell(acciones)="data">

            <!-- modulo="reunionesCoordinaciones" -->
            <apoyos-update
              :modal="'modal-update-'+data.item.id"
              :data="data"
              :idCurso="getLibroSelected.id"
            />

            <col-acciones-btnes
              :modal="'modal-update-'+data.item.id"
              :data="data"
              :modulo="nombre_permiso"
              @processRemove="remove(data.item)"
            />
          </template>
        </b-table>
        <b-alert
          v-if="items.length === 0"
          variant="primary"
          show
          class="text-center pt-25 pb-25"
        >
          <div class="alert-body">
            <span>No existen apoyos registrados.</span>
          </div>
        </b-alert>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>

    </b-row>
  </b-overlay>
</template>

<script>
// ETIQUETAS
import {
  BTable, BRow, BCol, BPagination, BFormCheckbox, BOverlay, BCardText,
  BButton, VBModal, BAlert
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'

// COMPONENTES RECICLADOS
// import inputFiltro from '../../../../../../components/List/inputFiltro.vue'
import btnMostrar from '../../../../../../components/List/btnMostrar.vue'
import btnCrearModal from '../../../../../../components/List/btnCrearModal.vue'
import colAccionesBtnes from '../../../../../../components/List/colAccionesBtnes.vue'
import colPeriodo from '../../../../../../components/List/colPeriodo.vue'
import colEstado from '../../../../../../components/List/colEstado.vue'
import spinner from '../../../../../../components/spinner.vue'
import colNombreImg from '../../../../../../components/List/colNombreImg.vue'

// HIJOS
import apoyosCreate from './Apoyos/ApoyosCreate.vue'
import apoyosUpdate from './Apoyos/ApoyosUpdate.vue'

// FORMATOS
import { formatos } from '@core/mixins/ui/formatos'

export default {
  components: {
    // ETIQUETAS
    BTable,
    BRow,
    BCol,
    BPagination,
    BFormCheckbox,
    BOverlay,
    BCardText,
    BButton,
    VBModal,
    BAlert,

    // COMPONENTES RECICLADOS
    // inputFiltro,
    colAccionesBtnes,
    btnMostrar,
    btnCrearModal,
    colPeriodo,
    colEstado,
    spinner,
    colNombreImg,

    // HIJOS
    apoyosCreate,
    apoyosUpdate,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [formatos],
  data() {
    return {
      nombre_permiso: 'pieII2B',
      cargando: false,
      spinner: false,
      items: [],

      // MODAL
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },

      // TABLE
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      fields: [
        {
          key: 'nombre',
          label: 'Ámbito, asignatura y/o Módulo de Aprendizaje',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '140px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'horas_aula',
          label: 'Horas de apoyo en aula regular',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'horas_fuera',
          label: 'Horas de apoyo fuera del aula',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'apoyos',
          label: 'Tipos de apoyo especializado (Recursos materiales y/o profesionales)',
          sortable: true,
          thStyle: {
            'text-align': 'center',
            width: '300px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '50px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getApoyos: 'II_2_b_apoyos/getApoyos',
      getLibroSelected: 'libros/getLibroSelected'
    }),

    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    getApoyos(val) {
      this.totalRows = val.length
      this.items = []
      this.items = this.getApoyos
    },
    getLibroSelected(val) {
      this.cargarApoyos(this.getLibroSelected.id)
    },
  },
  mounted() {
    this.cargarApoyos(this.getLibroSelected.id)
    this.setTableList()
  },
  methods: {
    ...mapActions({
      fetchApoyos: 'II_2_b_apoyos/fetchApoyos',
      removeApoyo: 'II_2_b_apoyos/removeApoyo',
    }),
    setTableList() {
      if (this.$can('update', this.nombre_permiso)
        || this.$can('delete', this.nombre_permiso)
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    cargarApoyos(idCurso) {
      this.fetchApoyos(idCurso).then(() => {
        this.cargando = false
      })
    },
    remove(apoyo) {
      const html = this.formatHTMLSweetEliminar('el apoyo', '')
      this.$swal({
        title: 'Eliminar apoyo!',
        html,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, elimínalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          const data = {
            id: apoyo.id,
            id_curso: this.getLibroSelected.id,
          }
          this.removeApoyo(data).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Eliminado con éxito!',
              text: `El apoyo ha sido eliminado!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
