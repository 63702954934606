var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('b-form',{ref:"form",staticClass:"repeater-form mb-3"},[_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Estilos y modos de aprendizaje del curso","label-for":"aprendizajes"}},[_c('b-form-textarea',{attrs:{"id":"aprendizajes","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa los estilos y modos de aprendizaje'
              : 'Sin información',"rows":"4","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.panorama.estilo.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.panorama.estilo.$touch($event)}},model:{value:(_vm.panorama.estilo),callback:function ($$v) {_vm.$set(_vm.panorama, "estilo", $$v)},expression:"panorama.estilo"}}),(_vm.v$.panorama.estilo.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"estiloInfo"}},_vm._l((_vm.v$.panorama.estilo.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Fortalezas del curso","label-for":"fortalezas"}},[_c('b-form-textarea',{attrs:{"id":"fortalezas","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa las fortalezas'
              : 'Sin información',"rows":"4","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.panorama.fortaleza.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.panorama.fortaleza.$touch($event)}},model:{value:(_vm.panorama.fortaleza),callback:function ($$v) {_vm.$set(_vm.panorama, "fortaleza", $$v)},expression:"panorama.fortaleza"}}),(_vm.v$.panorama.fortaleza.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"fortalezaInfo"}},_vm._l((_vm.v$.panorama.fortaleza.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Necesidades de apoyo del curso","label-for":"necesidades"}},[_c('b-form-textarea',{attrs:{"id":"necesidades","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa las necesidades'
              : 'Sin información',"rows":"4","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.panorama.necesidad.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.panorama.necesidad.$touch($event)}},model:{value:(_vm.panorama.necesidad),callback:function ($$v) {_vm.$set(_vm.panorama, "necesidad", $$v)},expression:"panorama.necesidad"}}),(_vm.v$.panorama.necesidad.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"necesidadInfo"}},_vm._l((_vm.v$.panorama.necesidad.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"md":"8"}}),_c('b-col',{attrs:{"md":"4"}},[_c('btnSubmit',{staticClass:"float-right",attrs:{"variant":"primary","modulo":_vm.nombre_permiso,"disabled":this.v$.panorama.$errors.length > 0,"btnText":_vm.btnSubmit},on:{"processBtn":_vm.submitOption}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }