<template>
  <b-card
    aria-hidden="true"
    class="mb-75 text-light bg-primary"

  >
    <b-form>
      <b-row>

        <!-- CURSO -->
        <b-col
          xs="12"
          md="5"
        >
          <p
            v-if="$can('create', 'profesorjefe')"
            class="text-left text-light mb-25 h4 font-weight-normal"
          >
            Libro
          </p>
          <p
            v-else
            class="text-left text-light mb-25 h4 font-weight-normal"
          >
            Libro
          </p>
          <b-overlay
            :show="cargando"
            spinner-variant="primary"
            variant="semi-dark"
          >
            <v-select
              v-model="idCurso"
              placeholder="Selecciona un libro..."
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="cursosOption"
              :reduce="option => option.idCurso"
              input-id="id_curso"
              class="bg-light mb-0 mt-25"
              style="border-radius: 6px;"
              :clearable="false"
              @input="changeLibro(idCurso)"
            />
          </b-overlay>
        </b-col>
        <b-col
          xs="12"
          md="1"
        ></b-col>

        <!-- PROFESOR JEFE -->
        <b-col
          v-if="idCurso"
          xs="12"
          md="5"

          class="mt-2 mt-md-0 ml-75"
        >
          <p
            v-if="$can('create', 'profesorjefe')"
            class="text-left text-light h4 mb-25 font-weight-normal"
          >
            Profesor jefe
          </p>
          <p
            v-else-if="profesorJefe"
            class="text-left text-center text-light mb-50 h4 font-weight-normal"
          >
            Profesor jefe
          </p>
          <b-overlay
            :show="cargandoProfesorJefe"
            spinner-variant="primary"
            variant="semi-dark"
          >
            <v-select
              v-model="id_persona_rol_profesor_jefe"
              v-if="$can('create', 'profesorjefe')"
              placeholder="Selecciona el profesor jefe..."
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="docentesOption"
              :reduce="option => option.id_persona_rol_profesor_jefe"
              input-id="id_curso"
              class="bg-light mb-0"
              style="border-radius: 6px;"
              :clearable="false"
              @input="submitProfesorJefe(id_persona_rol_profesor_jefe)"
            />
            <p
              v-else
              class="text-left text-center h5 text-white"
            >
              {{ profesorJefe.nombre }} {{ profesorJefe.segundo_nombre }} {{ profesorJefe.primer_apellido }} {{ profesorJefe.segundo_apellido }}
            </p>
          </b-overlay>
        </b-col>
        <b-col
          xs="12"
          md="1"
        ></b-col>

      </b-row>

      <!-- PANORAMA DEL CURSO -->
      <b-tabs
        v-if="panorama.estilo || panorama.fortaleza || panorama.necesidad"
        class="mt-1 text-light"
        style="margin-left: -21px;"
      >
        <b-tab
          v-if="panorama.estilo"
          active
          title="Estilos y modos de aprendizaje"
          class="ml-2"
        >
          <b-card-text class="text-light" style="margin-left: -4px;">
            {{ panorama.estilo }} aasas
          </b-card-text>
        </b-tab>
        <b-tab v-if="panorama.fortaleza" title="Fortalezas">
          <b-card-text class="text-light mb-2" style="margin-left: 17px;">
            {{ panorama.fortaleza }}
          </b-card-text>
        </b-tab>
        <b-tab v-if="panorama.necesidad" title="Necesidades de apoyo">
          <b-card-text class="text-light mb-2" style="margin-left: 17px;">
            {{ panorama.necesidad }}
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-form>
  </b-card>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BCard, BFormSelect, BCardText, BCardTitle,
  BTabs, BTab
} from 'bootstrap-vue'

import vSelect from 'vue-select'

import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, email, helpers } from '@vuelidate/validators'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    vSelect,
    BOverlay,
    BCard,
    BFormSelect,
    BCardText,
    BCardTitle,
    BTabs,
    BTab,
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      collapseType: 'border',
      cargando: true,
      cargandoProfesorJefe: true,
      idCurso: null,
      profesorJefe: '',
      id_persona_rol_profesor_jefe: null,
      cursosOption: [],
      docentesOption: [],
      panorama:[],
      libros: [],
    }
  },
  props: {
    libro: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      getLibroSelected: 'libros/getLibroSelected',
      getPanorama: 'II_1_a_panorama/getPanorama',
      getCursosPersonaRol: 'cursos/getCursosPersonaRol',
      getPersonasRol: 'personas/getPersonasRol',
      getUser: 'auth/user',
    }),
  },
  watch: {
    getPanorama(getPanorama) {
      if (getPanorama.message !== 'Registro no existe') {
        this.panorama = getPanorama
      } else {
        this.panorama = {
          'estilo': false,
          'fortaleza': false,
          'necesidad': false,
        }
      }
    },
    getCursosPersonaRol(getCursosPersonaRol) {
      this.libros = getCursosPersonaRol
      this.setCursosOption(getCursosPersonaRol)
    },
    getPersonasRol(getPersonasRol) {
      this.setDocentesOption(getPersonasRol)
    },
  },
  mounted() {
    this.setLibro()
    this.cargaCursosPersonaRol()
    this.cargaDocentes()
  },
  methods: {
    ...mapMutations('libros', ['setLibro']),
    ...mapActions({
      fetchPanorama: 'II_1_a_panorama/fetchPanorama',
      fetchCursosPersonaRol: 'cursos/fetchCursosPersonaRol',
      addProfesorJefe: 'cursos/addProfesorJefe',
      fetchPersonasRol: 'personas/fetchPersonasRol',
    }),
    cargaCursosPersonaRol() {
      this.fetchCursosPersonaRol()
    },
    cargaDocentes() {
      const data = {
        id_rol: 2, // Docente
        id_establecimiento: this.getUser.id_establecimiento,
      }
      this.fetchPersonasRol(data)
    },
    cargarPanorama(idCurso) {
      this.cargando = true
      this.fetchPanorama(this.idCurso).then(() => {
        if (this.getPanorama.message !== 'Registro no existe') {
          this.panorama = this.getPanorama
        } else {
          this.panorama = {
            'estilo': false,
            'fortaleza': false,
            'necesidad': false,
          }
        }
        this.cargando = false
      })
    },
    setCursosOption(libros) {
      if (libros.length != 0) {
        this.cargando = true
        this.cursosOption = []
        libros.forEach(libro => {
          this.cursosOption.push({
            'idCurso': libro.id,
            'title': libro.nombre+' '+libro.letra,
          })
        })
      }
      this.cargando = false
    },
    setDocentesOption(docentes) {
      this.cargandoProfesorJefe = true
      this.docentesOption = []
      docentes.forEach(docente => {
        const title = `${docente.nombre} ${docente.primer_apellido} ${docente.segundo_apellido}`
        this.docentesOption.push({
          'id_persona_rol_profesor_jefe': docente.id_persona_rol,
          title,
        })
        this.cargandoProfesorJefe = false
      })
    },
    changeLibro(id) {
      this.cargaCursosPersonaRol()
      const libroSelected = this.libros.find(l => l.id === id)
      this.setLibro(libroSelected)
      this.idCurso = this.getLibroSelected.id
      this.cargarPanorama(this.idCurso)
      this.cargandoProfesorJefe = true
      this.id_persona_rol_profesor_jefe = libroSelected.id_persona_rol_docente_jefe
      const profesor_jefe = this.getPersonasRol.find( pr => pr.id_persona_rol === this.id_persona_rol_profesor_jefe)
      if (typeof profesor_jefe !== 'undefined') {
        this.profesorJefe = profesor_jefe
      } else {
        this.profesorJefe = ''
      }
      this.cargandoProfesorJefe = false

    },
    submitProfesorJefe(id_persona_rol_jefe) {
      const data = {
        id_curso: this.idCurso,
        id_persona_rol_jefe,
      }
      this.addProfesorJefe(data).then(() => {
        const statusCursos = store.state.cursos.status
        if (statusCursos === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profesor jefe guardado 👍',
              icon: 'CheckIcon',
              text: 'El profesor jefe ha sido asignado con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'top-right',
            timeout: 4000,
          })
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.evaluaciones.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
    submitOption() {
      this.v$.libro.$touch()
      // if (!this.v$.libro.$invalid) {
      //   this.$emit('processForm', this.libro)
      // }
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>