var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.nombreModal,"title":_vm.title,"centered":"","size":"lg","cancel-title":"Cancelar","cancel-variant":"outline-secondary","ok-title":"Guardar Reunión","ok-disabled":this.v$.reunion.$errors.length > 0},on:{"ok":function($event){$event.preventDefault();return _vm.submitOption($event)}}},[_c('b-overlay',{attrs:{"show":!_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":"Fecha *","label-for":"fecha"}},[_c('b-form-datepicker',{attrs:{"id":"fecha","placeholder":"Abrir calendario","hide-header":"","date-format-options":{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short'
              },"start-weekday":"1","locale":"es-CL","date-disabled-fn":_vm.dateDisabled,"label-help":"","state":_vm.v$.reunion.fecha.$error === true
                ? false
                : null},nativeOn:{"blur":function($event){return _vm.v$.reunion.fecha.$touch($event)}},model:{value:(_vm.reunion.fecha),callback:function ($$v) {_vm.$set(_vm.reunion, "fecha", $$v)},expression:"reunion.fecha"}}),(_vm.v$.reunion.fecha.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"fechaInfo"}},_vm._l((_vm.v$.reunion.fecha.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":"Hora *","label-for":"horario"}},[_c('b-input-group',[_c('cleave',{class:_vm.v$.reunion.horario.$error === true
                  ? 'form-control border-danger rounded'
                  : 'form-control',attrs:{"id":"time","raw":false,"options":_vm.time,"placeholder":"hh:mm"},model:{value:(_vm.reunion.horario),callback:function ($$v) {_vm.$set(_vm.reunion, "horario", $$v)},expression:"reunion.horario"}}),_c('b-input-group-append',[_c('b-form-timepicker',{attrs:{"button-only":"","size":"sm","aria-controls":"horario","hide-header":"","right":"","locale":"es-CL","no-close-button":""},on:{"keyup":function($event){return _vm.formatHoraInput(_vm.reunion.horario)}},model:{value:(_vm.reunion.horario),callback:function ($$v) {_vm.$set(_vm.reunion, "horario", $$v)},expression:"reunion.horario"}})],1)],1),(_vm.v$.reunion.horario.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"diaInfo"}},_vm._l((_vm.v$.reunion.horario.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Asistentes *","label-for":"asistentes"}},[_c('v-select',{class:_vm.v$.reunion.asistentes.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"multiple":"","placeholder":"Selecciona los asistentes...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"texto","options":_vm.asistentesOption,"reduce":function (option) { return option.id; },"input-id":"asistentes"},model:{value:(_vm.reunion.asistentes),callback:function ($$v) {_vm.$set(_vm.reunion, "asistentes", $$v)},expression:"reunion.asistentes"}}),(_vm.v$.reunion.asistentes.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"asistentesInfo"}},_vm._l((_vm.v$.reunion.asistentes.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Acuerdos *","label-for":"acuerdos"}},[_c('b-form-textarea',{attrs:{"id":"acuerdos","placeholder":"Ingresa los acuerdos","rows":"4","state":_vm.v$.reunion.acuerdos.$error === true
                ? false
                : null},nativeOn:{"blur":function($event){return _vm.v$.reunion.acuerdos.$touch($event)}},model:{value:(_vm.reunion.acuerdos),callback:function ($$v) {_vm.$set(_vm.reunion, "acuerdos", $$v)},expression:"reunion.acuerdos"}}),(_vm.v$.reunion.acuerdos.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"acuerdosInfo"}},_vm._l((_vm.v$.reunion.acuerdos.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }