var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.nombreModal,"title":_vm.title,"centered":"","size":"lg","cancel-title":"Cancelar","cancel-variant":"outline-secondary","ok-title":"Guardar apoyo","ok-disabled":this.v$.apoyo.$errors.length > 0},on:{"ok":function($event){$event.preventDefault();return _vm.submitOption($event)}}},[_c('b-overlay',{attrs:{"show":!_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Ámbito, asignatura y/o Módulo de Aprendizaje *","label-for":"id_asignatura"}},[_c('v-select',{class:_vm.v$.apoyo.id_asignatura.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"placeholder":"Selecciona una asignatura...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"texto","options":_vm.asignaturasOption,"reduce":function (option) { return option.id; },"input-id":"idPeriodo"},model:{value:(_vm.apoyo.id_asignatura),callback:function ($$v) {_vm.$set(_vm.apoyo, "id_asignatura", $$v)},expression:"apoyo.id_asignatura"}}),(_vm.v$.apoyo.id_asignatura.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_asignaturaInfo"}},_vm._l((_vm.v$.apoyo.id_asignatura.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":"Horas de apoyo en aula regular *","label-for":"horas_aula"}},[_c('b-input-group',{class:_vm.v$.apoyo.horas_aula.$error === true
                  ? 'border-danger rounded'
                  : 'rounded'},[_c('b-form-input',{attrs:{"id":"horas_aula","type":"number","min":2,"placeholder":"Ingresa las horas de apoyo en aula"},on:{"keyup":function($event){_vm.apoyo.horas_aula = _vm.formatSoloNumerosUnDigito(_vm.apoyo.horas_aula)}},nativeOn:{"blur":function($event){return _vm.v$.apoyo.horas_aula.$touch($event)}},model:{value:(_vm.apoyo.horas_aula),callback:function ($$v) {_vm.$set(_vm.apoyo, "horas_aula", $$v)},expression:"apoyo.horas_aula"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v(" hrs ")])],1),(_vm.v$.apoyo.horas_aula.$error)?_c('div',{staticClass:"text-right text-danger",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"horas_aulaInfo"}},_vm._l((_vm.v$.apoyo.horas_aula.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('b-form-group',{attrs:{"label":"Horas de apoyo fuera del aula *","label-for":"horas_fuera"}},[_c('b-input-group',{class:_vm.v$.apoyo.horas_fuera.$error === true
                  ? 'border-danger rounded'
                  : 'rounded'},[_c('b-form-input',{attrs:{"id":"horas_fuera","type":"number","placeholder":"Ingresa las horas de apoyo fuera del aula"},on:{"keyup":function($event){_vm.apoyo.horas_fuera = _vm.formatSoloNumerosUnDigito(_vm.apoyo.horas_fuera)}},nativeOn:{"blur":function($event){return _vm.v$.apoyo.horas_fuera.$touch($event)}},model:{value:(_vm.apoyo.horas_fuera),callback:function ($$v) {_vm.$set(_vm.apoyo, "horas_fuera", $$v)},expression:"apoyo.horas_fuera"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v(" hrs ")])],1),(_vm.v$.apoyo.horas_fuera.$error)?_c('div',{staticClass:"text-right text-danger",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"horas_fueraInfo"}},_vm._l((_vm.v$.apoyo.horas_fuera.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Tipos de apoyo especializado (Recursos materiales y/o profesionales) *","label-for":"apoyos"}},[_c('b-form-textarea',{attrs:{"id":"apoyos","placeholder":"Ingresa los tipos de apoyo especializado","rows":"4","state":_vm.v$.apoyo.apoyos.$error === true
                ? false
                : null},nativeOn:{"blur":function($event){return _vm.v$.apoyo.apoyos.$touch($event)}},model:{value:(_vm.apoyo.apoyos),callback:function ($$v) {_vm.$set(_vm.apoyo, "apoyos", $$v)},expression:"apoyo.apoyos"}}),(_vm.v$.apoyo.apoyos.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"apoyosInfo"}},_vm._l((_vm.v$.apoyo.apoyos.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }