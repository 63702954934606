<template>
  <b-overlay
    v-if="$can('read', 'asistencias')"
    :show="cargando"
    spinner-variant="primary"
    variant="semi-dark"
    style="min-height: 605px !important;"
  >
    <asistencias-alumnos-horario
      :diaSelected.sync="diaSelected"
      :mesSelected.sync="mesSelected"
      @cargarDatosMensual="cargarAsistenciasMensual"
      @initialState="initialState"
    />
    <b-button
      variant="primary"
      class="btn btn-sm float-right mb-1"
      @click="imprimir"
    >
      <b-overlay
        :show="cargandoPdf"
        spinner-variant="primary"
        variant="semi-dark"
      >
        <span class="text-nowrap">Ver PDF</span>
      </b-overlay>
    </b-button>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="`Resumen de Asistencia ${nombreCurso}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="legal"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
      margin="5"
    >
      <section slot="pdf-content" class="pr-2 pl-2 pt-2">
        <!-- Renderizar múltiples tablas -->
        <div v-for="(group, index) in chunkedItems" :key="index">
          <h4 :class="index === 0 ? 'mb-2 text-center' : 'mt-2 mb-2 text-center'">Resumen Asistencia {{ nombreCurso }} - {{ mes[mesSelected - 1] }}</h4>
          <table class="small" style="border: 1px solid !important;">
            <tr>
              <th class="text-center border" v-for="(field, key) in fields" :key="key" :style="`width: ${field.width};`">
                {{ field.label }}
              </th>
            </tr>
            <tr v-for="(item, key) in group" :key="key">
              <td class="border text-center">{{ item.orden }}</td>
              <td class="border">{{ item.nombre_completo }}</td>
              <template v-for="(asistencia, key2) in item.dias">
                <td :key="key2" class="border text-center">
                  <feather-icon
                    v-if="getTipoAsistencia[asistencia.tipo_asistencia-1]"
                    :icon="getTipoAsistencia[asistencia.tipo_asistencia-1].tipo === 2 ? 'CheckIcon' : 'XIcon'"
                    size="14"
                  />
                  <div v-else>-</div>
                </td>
              </template>
            </tr>
          </table>
          <!-- Salto de página entre tablas -->
          <div v-if="index !== chunkedItems.length - 1" class="break-page"></div>
        </div>
      </section>
    </vue-html2pdf>
    <b-alert
      v-if="bloquesAlert"
      variant="info"
      show
      class="text-center mt-25 mr-25 ml-25 pb-2 pt-2"
    >
      {{alertMsg}}
    </b-alert>
    <b-table
      v-else
      small
      hover
      noCollapse
      bordered
      class="mt-0 mb-2 rounded"
      :style="cargando ? 'min-height: 605px !important;' : 'overflow-x:auto; min-height: 605px !important;'"
      responsive
      sticky-header
      :fields="fields"
      :items="items"
      @head-clicked="clickTitleTable"
    >
      <!-- caption-html="aaa" -->
      <!-- @head-clicked="" -->
      <!-- Cargando -->
      <template #table-busy>
        <div class="text-center text-danger my-1">
          <spinner />
        </div>
      </template>

      <!-- <template #cell(nombre_completo)="data">
        <div class="text-center text-danger my-1">
          aaaaaaaaa
        </div>
      </template> -->

      <template
        #cell()="data"
      >
        <div
          v-if="data.field.key == 'num_lista'"
        >
          <estudiante-retirado
            :rut="data.item.rut"
            :data.sync="data.item.orden.toString()"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :fecha_ingreso.sync="data.item.fecha_ingreso"
            :genero.sync="data.item.genero"
            :mayus="false"
          />
        </div>
        <div
          class="divEstudiante"
          v-else-if="data.field.key == 'nombre_completo'"
        >
          <estudiante-retirado
            :rut="data.item.rut"
            :data.sync="data.item.nombre_completo"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :fecha_ingreso.sync="data.item.fecha_ingreso"
            :genero.sync="data.item.genero"
            :mayus="true"
          />
        </div>

        <asistencia-alumno-dia
          v-else-if="data.field.dia"
          :item.sync="data.item"
          :field.sync="data.field"
          :tipoAsistencia.sync="tipoAsistencia"
        />

      </template>

    </b-table>
  </b-overlay>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BButtonGroup, BAvatar, BOverlay, BCard, BTab, BTabs,
  BFormRadioGroup, BTable, BCardText, BAlert
} from 'bootstrap-vue'

import VueHtml2pdf from 'vue-html2pdf'

import Ripple from 'vue-ripple-directive'

import { mapGetters, mapActions } from 'vuex'

// COMPONENTES RECICLADOS
import spinner from '../../../../components/spinner.vue'

// COMPONENTES HIJOS
import AsistenciaAlumnoDia from './components/AsistenciaAlumnoDia.vue'
import AsistenciasAlumnosHorario from '../AsistenciasDia/AsistenciasAlumnosHorario.vue'
import estudianteRetirado from '../../../../components/List/estudianteRetirado.vue'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
    BMedia, BButton, BButtonGroup, BAvatar, BOverlay, BCard, BTab, BTabs,
    BFormRadioGroup, BTable, BCardText, BAlert,
    VueHtml2pdf,

    // COMPONENTES RECICLADOS
    spinner,
    estudianteRetirado,

    // COMPONENTES HIJOS
    AsistenciaAlumnoDia,
    AsistenciasAlumnosHorario,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // data
      cargando: true,
      cargandoPdf: false,
      bloquesAlert: false,
      alertMsg: '',
      items: [],
      tipoAsistencia: [],
      diaSelected: { state: false },
      fields: [],
      year: new Date().getFullYear(),
      nombreCurso: null,
      mes: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    }
  },
  computed: {
    ...mapGetters({
      getAsistenciasMensual: 'asistencias/getAsistenciasMensual',
      getTipoAsistencia: 'asistencias/getTipoAsistencia',
      getLibroSelected: 'libros/getLibroSelected',
    }),
    chunkedItems() {
      const chunkSize = 20; // Cambia esto según tus necesidades
      return this.items.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize)
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
      }, [])
    },
  },
  props: {
    mesSelected: {
      type: Number,
      required: true,
    },
  },
  watch: {
    getAsistencias(val) {
      this.cargaItems(val)
    },
    getAsistenciasMensual(val) {
      if (val.length !== 0) {
        this.setAlumnosAsistencias(val)
        this.cargarDias(val[0]['dias'])
      } else {
        this.cargando = false
        this.bloquesAlert = true
        this.alertMsg = 'El curso no tiene Estudiantes Matriculados.'
      }
    },
    getTipoAsistencia(val) {
      this.tipoAsistencia = val
    },
    mesSelected(val) {
      this.cargarAsistenciasMensual(this.getLibroSelected.id)
      this.cargando = true
    },
    getLibroSelected(val) {
      this.setNombreCurso()
      this.cargando = true
    },
  },
  mounted() {
    this.cargarTipoAsistencia()
    this.cargarAsistenciasMensual(this.getLibroSelected.id)
    this.setNombreCurso()
  },
  methods: {
    ...mapActions({
      fetchAsistenciasMensual: 'asistencias/fetchAsistenciasMensual',
      fetchTipoAsistencia: 'asistencias/fetchTipoAsistencia',
    }),
    setNombreCurso() {
      this.nombreCurso = this.getLibroSelected.nombre+' '+this.getLibroSelected.letra
    },
    imprimir() {
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress(event) {
      if (event === 70) {
        this.cargandoPdf = true
      } else if (event === 100) {
        this.cargandoPdf = false
      }
    },
    cargarAsistenciasMensual(id_curso) {
      const data = {
        id_curso,
        mes: this.mesSelected
      }
      this.fetchAsistenciasMensual(data)
    },
    setAlumnosAsistencias(estudiantes) {
      for (let i = 0; i < estudiantes.length; i++) {
        let estudiante = estudiantes[i];
        estudiante['nombre_completo'] = `${estudiante.primer_apellido}
                  ${estudiante.segundo_apellido}
                  ${estudiante.nombre}
                  ${estudiante.segundo_nombre !== null
                    ? estudiante.segundo_nombre
                    : ''}
                  `
        estudiante['fecha_retiro'] = estudiante.fecha_retiro
      }
      this.items = []
      this.items = estudiantes
    },
    cargarTipoAsistencia() {
      this.cargando = true
      this.fetchTipoAsistencia()
    },
    cargarDias(dias) {
      const hoy = new Date();
      this.setField()

      dias.forEach(dia => {

        let tdClass = ''
        let thClass = ''
        let background = ''
        if (
          hoy.getDate() === dia.numeroDia
          && hoy.getMonth() + 1 === this.mesSelected
        ) {
          if (this.$store.state.appConfig.layout.skin === 'dark') {
            tdClass = 'text-center bg-light-primary'
            thClass = 'text-primary'
            background = '#e4f2fb !important'
          } else {
            tdClass = 'text-center bg-light-primary'
            thClass = 'text-primary'
            background = '#e4f2fb !important'
          }
        } else if (dia.isWeek) {
          if (this.$store.state.appConfig.layout.skin === 'dark') {
            tdClass = 'text-center'
            background = '#e3e3e5 !important'
          } else {
            tdClass = 'text-center bg-light-secondary'
            background = '#e3e3e5 !important'
          }
        } else {
          if (this.$store.state.appConfig.layout.skin === 'dark') {
            tdClass = 'text-center bg-light-dark'
            thClass = 'bg-light bg-lighten-5'
          } else {
            tdClass = 'text-center bg-light-light'
            thClass = 'bg-light bg-lighten-5'
          }
        }

        const fecha = new Date(this.year, this.mesSelected - 1, dia.numeroDia)
        const cursor = fecha > hoy || dia.isWeek
        this.fields.push(
          {
            state: true,
            key: 'dia_'+dia.numeroDia,
            label: dia.nombreDia+' '+dia.numeroDia+'',
            tdClass,
            dia: true,
            numeroDia: dia.numeroDia,
            tipoAsistencia: dia.tipoAsistencia,
            isWeek: dia.isWeek,
            futureDay: fecha > hoy,
            thClass,
            thStyle: {
              'cursor' : cursor ? '' : 'pointer',
              'text-align': 'center',
              width: '5% !important',
              display: 'table-cell',
              'vertical-align': 'middle',
              background,
            },
          },
        )
      })
      this.cargando = false
    },
    initialState() {
      this.diaSelected = { state: false }
    },
    clickTitleTable(key, field) {
      if (!field.isWeek && !field.futureDay) {
        this.diaSelected = field
        this.$bvModal.show('asistencia_dias')
      }
    },
    setField() {
      this.fields = []
      this.fields = [
        {
          key: 'num_lista',
          label: '#',
          // stickyColumn: true,
          // sortable: true,
          variant: 'primary',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '1% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre_completo',
          label: 'Estudiantes',
          stickyColumn: true,
          // sortable: true,
          variant: 'light',
          thStyle: {
            width: '33% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ]
    },
  },
}
</script>

<style>
.break-page {
  page-break-after: always;
}
</style>
