var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('b-form',{ref:"form",staticClass:"mb-2"},[_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Entre profesores (educación especial y profesor regular) <Co-enseñanza>","label-for":"profesores"}},[_c('b-form-textarea',{attrs:{"id":"profesores","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa las estrategias y/o acciones entre profesores'
              : 'Sin información',"rows":"4","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.acciones.profesores.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.acciones.profesores.$touch($event)}},model:{value:(_vm.acciones.profesores),callback:function ($$v) {_vm.$set(_vm.acciones, "profesores", $$v)},expression:"acciones.profesores"}}),(_vm.v$.acciones.profesores.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"profesoresInfo"}},_vm._l((_vm.v$.acciones.profesores.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Entre profesores y asistentes de la educación (psicólogos, fonoaudiólogos, auxiliares, intérpretes, etc.)","label-for":"asistentes"}},[_c('b-form-textarea',{attrs:{"id":"asistentes","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa las estrategias y/o acciones entre profesores y asistentes de la educación'
              : 'Sin información',"rows":"4","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.acciones.asistentes.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.acciones.asistentes.$touch($event)}},model:{value:(_vm.acciones.asistentes),callback:function ($$v) {_vm.$set(_vm.acciones, "asistentes", $$v)},expression:"acciones.asistentes"}}),(_vm.v$.acciones.asistentes.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"asistentesInfo"}},_vm._l((_vm.v$.acciones.asistentes.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Entre estudiantes (Ej: Aprendizaje Colaborativo)","label-for":"estudiantes"}},[_c('b-form-textarea',{attrs:{"id":"estudiantes","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa las estrategias y/o acciones entre estudiantes'
              : 'Sin información',"rows":"4","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.acciones.estudiantes.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.acciones.estudiantes.$touch($event)}},model:{value:(_vm.acciones.estudiantes),callback:function ($$v) {_vm.$set(_vm.acciones, "estudiantes", $$v)},expression:"acciones.estudiantes"}}),(_vm.v$.acciones.estudiantes.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"estudiantesInfo"}},_vm._l((_vm.v$.acciones.estudiantes.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Con la familia y entre familias","label-for":"familia"}},[_c('b-form-textarea',{attrs:{"id":"familia","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa las estrategias y/o acciones con la familia y entre familia'
              : 'Sin información',"rows":"4","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.acciones.familia.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.acciones.familia.$touch($event)}},model:{value:(_vm.acciones.familia),callback:function ($$v) {_vm.$set(_vm.acciones, "familia", $$v)},expression:"acciones.familia"}}),(_vm.v$.acciones.familia.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"familiaInfo"}},_vm._l((_vm.v$.acciones.familia.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Con la comunidad (con otras Escuelas, Centros Culturales, Servicios, etc)","label-for":"comunidad"}},[_c('b-form-textarea',{attrs:{"id":"comunidad","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa las estrategias y/o acciones con la comunidad'
              : 'Sin información',"rows":"4","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.acciones.comunidad.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.acciones.comunidad.$touch($event)}},model:{value:(_vm.acciones.comunidad),callback:function ($$v) {_vm.$set(_vm.acciones, "comunidad", $$v)},expression:"acciones.comunidad"}}),(_vm.v$.acciones.comunidad.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"comunidadInfo"}},_vm._l((_vm.v$.acciones.comunidad.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Observaciones","label-for":"observaciones"}},[_c('b-form-textarea',{attrs:{"id":"observaciones","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Ingresa las observaciones'
              : 'Sin información',"rows":"4","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.acciones.observaciones.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.acciones.observaciones.$touch($event)}},model:{value:(_vm.acciones.observaciones),callback:function ($$v) {_vm.$set(_vm.acciones, "observaciones", $$v)},expression:"acciones.observaciones"}}),(_vm.v$.acciones.observaciones.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"observacionesInfo"}},_vm._l((_vm.v$.acciones.observaciones.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"md":"8"}}),_c('b-col',{attrs:{"md":"4"}},[(!_vm.cargando)?_c('btnSubmit',{staticClass:"float-right",attrs:{"variant":"primary","modulo":_vm.nombre_permiso,"disabled":this.v$.acciones.$errors.length > 0,"btnText":_vm.btnSubmit},on:{"processBtn":_vm.submitOption}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }