<template>
  <div>
    <b-tabs
      style="margin: -10px -10px -26px -10px!important;"
      align="center"
      fill
    >
      <!-- HOY -->
      <b-tab title="Hoy" lazy>
        <template #title>
          <feather-icon icon="UserCheckIcon"
            size="18"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">
            {{ fechaHoy === formatFechaVerActividades(new Date())
                ? 'Hoy'
                : 'Diario'
            }} - {{ fechaHoy }}
          </span>
        </template>
        <colLinea style="margin-top: -15px; margin-bottom: 16px;"/>

        <!--  -->
        <b-row class="text-left">
          <b-col
            cols="8"
            xl="9"
            lg="8"
            md="8"
          ></b-col>
          <b-col
            cols="12"
            xl="3"
            lg="4"
            md="4"
            xs="6"
            class="mb-1"
          >
            <calendario
              id="fecha"
              tipo="hastaFechaActual"
              :fecha="fechaCalendario"
              :state="false"
              @changeCalendario="cambiaDia"
            />
          </b-col>
        </b-row>

        <!-- TABLA ASISTENCIAS DIARIA -->
        <asistencias-alumnos-dia
          v-if="cargarAsistenciasAlumnosDia"
          :diaSelected="hoy.dia"
          :mesSelected="hoy.mes"
        />
      </b-tab>

      <!-- MES -->
      <b-tab title="Mensual" lazy>
        <template #title>
          <feather-icon icon="CalendarIcon"
            size="18"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">
            Mensual - {{meses[mes - 1]}}
          </span>
        </template>
        <colLinea style="margin-top: -15px; margin-bottom: 16px;"/>

        <b-row>

          <!-- Field: Meses -->
          <b-col cols="12" md="6">
            <b-alert
              show
              variant="primary"
              style="margin-top: -5px"
            >
              <div class="alert-body text-center mt-25">
                <feather-icon icon="InfoIcon" class="mr-50"/>
                Para ver en detalle la asistencia de un día, haz click en el título del día.
              </div>
            </b-alert>
          </b-col>
          <b-col
            cols="12"
            md="3"
          ></b-col>
          <!-- Field: Meses -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label-for="mes"
            >
              <v-select
                v-model="mes"
                placeholder="Selecciona un mes..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="mesesOption"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <asistencias-alumnos-mes
          :mesSelected.sync="mes"
        />

      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BTabs, BTab, BAlert, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

// MIXINS
import { formatos } from '@core/mixins/ui/formatos'


// COMPONENTES HIJOS
import AsistenciasAlumnosMes from './AsistenciasMes/AsistenciasAlumnosMes.vue'
import AsistenciasAlumnosDia from './AsistenciasDia/AsistenciasAlumnosDia.vue'

import { mapGetters, mapActions } from 'vuex'

import colLinea from '../../../components/Form/colLinea.vue'
import calendario from '../../../components/Form/calendario.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTabs,
    BTab,
    BAlert,
    BFormDatepicker,
    vSelect,
    colLinea,

    // COMPONENTES HIJOS
    AsistenciasAlumnosMes,
    AsistenciasAlumnosDia,
    calendario,
  },
  mixins: [formatos],
  data() {
    return {
      fechaCalendario: '',
      meses: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciciembre',
      ],
      cargarAsistenciasAlumnosDia: false,
      fechaHoy: this.formatFechaVerActividades(new Date()),
      hoy: {
        dia: null,
        mes: null,
      },
      mesActual: new Date().getMonth() + 1,
      mes: new Date().getMonth() + 1,
      mesesOption: [],
      mesesDefault: [
        {
          id: 3,
          texto: 'Marzo'
        },
        {
          id: 4,
          texto: 'Abril'
        },
        {
          id: 5,
          texto: 'Mayo'
        },
        {
          id: 6,
          texto: 'Junio'
        },
        {
          id: 7,
          texto: 'Julio'
        },
        {
          id: 8,
          texto: 'Agosto'
        },
        {
          id: 9,
          texto: 'Septiembre'
        },
        {
          id: 10,
          texto: 'Octubre'
        },
        {
          id: 11,
          texto: 'Noviembre'
        },
        {
          id: 12,
          texto: 'Diciembre'
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getLibroSelected: 'libros/getLibroSelected',
    }),
  },
  watch: {
    getLibroSelected(val) {
      this.setHoy()
      this.cargarMeses()
      this.cargarAsistenciasMensual(this.getLibroSelected.id)
    },
    mes(val) {
      this.cargarAsistenciasMensual(this.getLibroSelected.id)
    }
  },
  mounted() {
    this.setHoy()
    this.cargarMeses()
    this.cargarAsistenciasMensual(this.getLibroSelected.id)
  },
  methods: {
    ...mapActions({
      fetchAsistenciasMensual: 'asistencias/fetchAsistenciasMensual',
    }),
    cargarMeses() {
      this.mesesOption = []
      for (let index = 3; index <= this.mesActual; index++) {
        const mes = this.mesesDefault.find(md => md.id === index)
        this.mesesOption.push({
            id: mes.id,
            texto: mes.texto
        })
      }
    },
    setHoy() {
      this.hoy.dia = null
      this.hoy.mes = null
      const hoy = new Date()
      this.hoy.year = hoy.getFullYear();
      this.hoy.dia = hoy.getDate();
      this.hoy.mes = hoy.getMonth() + 1;
      this.fechaCalendario = this.hoy.year+'-'+this.hoy.mes+'-'+this.hoy.dia
      this.cargarAsistenciasAlumnosDia = true
    },
    cargarAsistenciasMensual(id_curso) {
      const data = {
        id_curso,
        mes: this.mes
      }

      this.fetchAsistenciasMensual(data).then(() => {})
    },
    cambiaDia(fechaCalendario) {
      this.fechaCalendario = fechaCalendario
      const fechaSeparada = this.fechaCalendario.split('-')
      const fecha = new Date(fechaSeparada)
      this.fechaHoy = this.formatFechaVerActividades(fecha)
      this.hoy.mes = parseInt(fechaSeparada[1])
      this.hoy.dia = parseInt(fechaSeparada[2])
    },

  }
}
</script>
