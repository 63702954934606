<template>
  <b-modal
    :id="nombreModal"
    :title="title"
    centered
    size="lg"
    :hide-footer="true"
    :ok-title="title"
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    :ok-disabled="this.v$.observacion.$errors.length > 0"
    @ok.prevent="submitOption"
  >
    <b-overlay
      :show="cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >

      <!-- Observaciones FORM -->
      <b-form>
        <b-row>

          <!-- Field: ASIGNATURA -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Asignatura"
              label-for="id_asignatura"
            >
              <v-select
                v-model="observacion.id_asignatura"
                placeholder="Selecciona una asignatura..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nombre"
                :options="optionsAsignaturas"
                :reduce="option => option.id"
                input-id="id_asignatura"
                :class="v$.observacion.id_asignatura.$error === true
                  ? 'border-danger rounded'
                  : ''"
                :disabled="true"
                :clearable="false"
              />
              <!-- :disabled="observacion.id >= 0" -->
              <div
                v-if="v$.observacion.id_asignatura.$error"
                id="id_asignaturaInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.observacion.id_asignatura.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </b-form-group>
          </b-col>

          <!-- Field: FECHA -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Fecha *"
              label-for="fecha"
            >
              <calendario
                id="fecha"
                tipo="hastaFechaActual"
                :fecha="observacion.fecha"
                :state="v$.observacion.fecha.$error"
                @changeCalendario="changeCalendario"
              />

              <!-- Mensajes Error Validación -->
              <b-form-invalid-feedback
                v-if="v$.observacion.fecha.$error"
                id="fechaInfo"
                class="text-right"
              >
                <p v-for="error of v$.observacion.fecha.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <!-- Field: ESTUDIANTE -->
          <b-col
            :cols="typeof observacion.id === 'undefined' ? '12' : '12'"
            :sm="typeof observacion.id === 'undefined' ? '12' : '12'"
            :md="typeof observacion.id === 'undefined' ? '6' : '12'"
            :lg="typeof observacion.id === 'undefined' ? '8' : '12'"
          >
            <b-form-group
              label="Estudiante(s) *"
              label-for="id_asignatura"
            >
              <v-select
                v-model="observacion.id_persona_rol_estudiante"
                :multiple="typeof observacion.id === 'undefined'"
                :disabled="typeof observacion.id !== 'undefined'"
                placeholder="Selecciona uno o varios estudiantes..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nombre"
                :options="optionsEstudiantes"
                :reduce="option => option.id_persona_rol_estudiante"
                input-id="id_persona_rol_estudiante"
                :class="v$.observacion.id_persona_rol_estudiante.$error === true
                  ? 'border-danger rounded'
                  : ''"
                :clearable="false"
              />
              <div
                v-if="v$.observacion.id_persona_rol_estudiante.$error"
                id="id_persona_rol_estudianteInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.observacion.id_persona_rol_estudiante.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </b-form-group>
          </b-col>
          <!-- btn: SELECCIONAR TODO -->
          <b-col
            v-if="typeof observacion.id === 'undefined'"
            cols="6"
            sm="6"
            md="3"
            lg="2"
          >
            <b-button
              class="mt-0 mt-md-2 mb-1 mb-md-0"
              size="sm"
              block
              variant="secondary"
              @click="seleccionarTodos()"
            >
              Seleccionar todo
            </b-button>

          </b-col>
          <!-- btn: BORRAR TODO -->
          <b-col
            v-if="typeof observacion.id === 'undefined'"
            cols="6"
            sm="6"
            md="3"
            lg="2"
          >
            <b-button
              class="mt-0 mt-md-2 mb-1 mb-md-0"
              size="sm"
              block
              variant="outline-secondary"
              @click="borrarTodos()"
            >
              Eliminar todo
            </b-button>

          </b-col>

          <!-- Field: TIPO -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Tipo *"
              label-for="tipo"
            >
              <v-select
                v-model="observacion.tipo"
                placeholder="Selecciona un tipo de observación..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nombre"
                :options="optionsTiposObservacion"
                :reduce="option => option.id"
                input-id="tipo"
                :class="v$.observacion.tipo.$error === true
                  ? 'border-danger rounded'
                  : ''"
                :clearable="false"
              />
              <div
                v-if="v$.observacion.tipo.$error"
                id="tipoInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.observacion.tipo.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </b-form-group>
          </b-col>

          <!-- Field: OBSERVACIONES -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Observaciones u Anotaciones *"
              label-for="observacion"
            >
              <b-form-textarea
                id="observacion"
                v-model="observacion.observacion"
                rows="4"
                placeholder="Ingresa una breve descripción de la observación u anotación"
                :state="v$.observacion.observacion.$error === true
                  ? false
                  : null"
                @blur.native="v$.observacion.observacion.$touch"
              />
              <b-form-invalid-feedback
                v-if="v$.observacion.observacion.$error"
                id="observacionInfo"
                class="text-right"
              >
                <p v-for="error of v$.observacion.observacion.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

        </b-row>

        <colLinea />

        <!-- ACTION BUTTONS -->
        <div class="text-right">

          <!-- CERRAR -->
          <b-button
            variant="outline-secondary"
            class="mr-1"
            @click="closeModal"
          >
            Cancelar
          </b-button>

          <!-- GUARDAR -->
          <b-button
            v-if="$can('create' , 'hojavida') || $can('update', 'hojavida')"
            variant="primary"
            class="mr-25"
            @click="submitOption"
          >
            {{ title }}
          </b-button>
        </div>

      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BFormDatepicker, BFormTimepicker,
  BFormTextarea, BModal, VBModal, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

// CLEAVE
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, email, helpers } from '@vuelidate/validators'

// COMPONENTES RECICLADOS
import colLinea from '../../../../components/Form/colLinea.vue'
import btnSubmit from '../../../../components/Form/btnSubmit.vue'
import calendario from '../../../../components/Form/calendario.vue'

export default {
  components: {
    // ETIQUETAS
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    BOverlay,
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    VBModal,
    vSelect,
    Cleave,

    // COMPONENTES RECICLADO
    colLinea,
    btnSubmit,
    calendario,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      cargando: false,
      periodosOption: [],
      optionsAsignaturas: [],
      optionsEstudiantes: [],
      optionsTiposObservacion: [],
    }
  },
  props: {
    estudiantes: {
      type: Array,
      required: true,
    },
    asignaturas: {
      type: Array,
      required: true,
    },
    asignaturaIndex: {
      type: Number,
      required: true,
    },
    tipos: {
      type: Array,
      required: true,
    },
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    observacion: {
      type: Object,
      required: true,
    },
  },
  watch: {
    estudiantes(estudiantes) {
      this.cargaEstudiantes(estudiantes)
    },
    asignaturas(asignaturas) {
      this.cargaAsignaturas(asignaturas)
    },
    asignaturaIndex(asignaturaIndex) {
      this.setAsignatura(asignaturaIndex)
    },
  },
  validations() {
    return {
      observacion: {
        fecha: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        tipo: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        observacion: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 500 caracteres.', maxLength(500)),
        },
        id_asignatura: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_persona_rol_estudiante: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
      }
    }
  },
  mounted() {
    this.setAsignatura(this.asignaturaIndex)
    this.cargaAsignaturas(this.asignaturas)
    this.cargaEstudiantes(this.estudiantes)
    this.cargaTipos(this.tipos)
  },
  methods: {
    setAsignatura(asignaturaIndex) {
      this.observacion.id_asignatura = this.asignaturas[asignaturaIndex - 1]
    },
    cargaEstudiantes(estudiantes) {
      this.optionsEstudiantes = []
      estudiantes.forEach(estudiante => {
        this.optionsEstudiantes.push({
          nombre: estudiante.nombre,
          id_persona_rol_estudiante: estudiante.id_persona_rol_estudiante
        })
      })
    },
    cargaAsignaturas(asignaturas) {
      this.optionsAsignaturas = []
      this.optionsAsignaturas = asignaturas
    },
    cargaTipos(tipos) {
      this.optionsTiposObservacion = []
      this.optionsTiposObservacion = tipos
    },
    seleccionarTodos() {
      this.borrarTodos()
      this.optionsEstudiantes.forEach(estudiante => {
        this.observacion.id_persona_rol_estudiante.push(estudiante.id_persona_rol_estudiante)
      })
    },
    borrarTodos() {
      this.observacion.id_persona_rol_estudiante = []
    },
    submitOption() {
      this.v$.observacion.$touch()
      if (!this.v$.observacion.$invalid) {
        let data
        if (typeof this.observacion.id === 'undefined') {
          const alumnos = []
          this.observacion.id_persona_rol_estudiante.forEach(id_alumno => {
            alumnos.push({'id_alumno' : id_alumno})
          })
          data = {
            fecha: this.observacion.fecha,
            tipo: this.observacion.tipo,
            observacion: this.observacion.observacion,
            id_asignatura: this.observacion.id_asignatura.id,
            alumnos,
          }
        } else {
          data = {
            id_observacion: this.observacion.id,
            fecha: this.observacion.fecha,
            tipo: this.observacion.tipo,
            observacion: this.observacion.observacion,
            id_asignatura: this.observacion.id_asignatura.id,
            id_persona_rol_estudiante: this.observacion.id_persona_rol_estudiante
          }
        }
        this.$emit('processForm', data)
        this.v$.observacion.$reset()
      }
    },
    changeCalendario(fecha) {
      this.observacion.fecha = fecha
    },
    closeModal() {
      if (this.observacion.id >= 0) {
        this.$bvModal.hide(this.nombreModal)
        this.v$.observacion.$reset()
      } else {
        this.$bvModal.hide(this.nombreModal)
        this.$emit('resetData')
        this.v$.observacion.$reset()
      }
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
