// We haven't added icon's computed property because it makes this mixin coupled with UI
export const compara = {
  data() {
    return {
      // passwordFieldType: 'password',
    }
  },
  methods: {
    comparaFechas(fechaRetiro, fechaInicio, fechaCompara) {
      let disabled = false
      if (fechaRetiro !== null) {
        const fr =  fechaRetiro.split('-')
        const fc =  fechaCompara.split('-')
        const fecha_retiro = new Date(fr[0], fr[1] - 1, fr[2])

        const fecha_compara = new Date(fc[0], fc[1] - 1, fc[2])
        disabled = fecha_compara >= fecha_retiro
      } else if (fechaInicio !== null) {
        const fr =  fechaInicio.split('-')
        const fc =  fechaCompara.split('-')
        const fecha_inicio = new Date(fr[0], fr[1] - 1, fr[2])

        const fecha_compara = new Date(fc[0], fc[1] - 1, fc[2])
        disabled = fecha_compara < fecha_inicio
      }
      return disabled
    },

  },
}

export const _ = null
