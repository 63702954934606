<template>
  <div>
    <!-- Vista CELULAR -->
    <b-card
      v-if="disabledList"
      class="mb-75 mt-0 pb-0 pt-0 bg-primary"
    >
      <b-card-title class="mb-0 mt-0 pb-0 pt-0 text-light text-center">
        {{ tituloMenu }}
      </b-card-title>
    </b-card>

    <!-- Vista NORMAL -->
    <!-- MENÚS -->
    <b-card
      aria-hidden="true"
      class="mb-75"
    >
      <b-tabs
        v-model="tabIndex"
        class="primary"
        style="margin: -10px -10px -26px -10px!important; text-light"
        justified
        pills
      >

        <b-tab
          lazy
          v-for="i in itemsTabs"
          :key="'tab-' + i.id"
          :title="i.title"
          :active="i.active"
          v-if="$can('read', i.modulo)"
        >
          <template
            #title
          >
            <feather-icon
              :icon="i.icon"
              size="18"
              class="mr-0 mr-sm-50"
            />
            <span
              class="d-none d-sm-inline"
            >
              {{i.title}}
            </span>
          </template>

        </b-tab>
      </b-tabs>
    </b-card>

    <!-- MÓDULOS -->
    <template
      v-for="(item, index) in itemsTabs"
    >
      <div v-if="item.modulo === 'lista' && item.can">
        <b-card
          v-if="tabIndex === index && getLibroSelected"
          aria-hidden="false"
        >
          <lista v-if="tabIndex === index"/>
        </b-card>
      </div>

      <!-- NOTAS -->
      <div v-if="item.modulo === 'notas' && item.can">
        <b-card
          v-if="tabIndex === index && getLibroSelected"
          aria-hidden="false"
        >
          <notas v-if="tabIndex === index"/>
        </b-card>
      </div>

      <div v-if="item.modulo === 'notasacumulativas' && item.can">
        <b-card
          v-if="tabIndex === index && getLibroSelected"
          aria-hidden="false"
        >
          <notas-acumulativas v-if="tabIndex === index && getConfiguraciones.sumativa === 1"/>
        </b-card>
      </div>

      <div v-if="item.modulo === 'notasformativas' && item.can">

        <b-card
          v-if="tabIndex === index && getLibroSelected"
          aria-hidden="false"
        >
          <notas-formativas v-if="tabIndex === index && getConfiguraciones.sumativa === 1"/>
        </b-card>
      </div>

      <div v-if="item.modulo === 'asistencias' && item.can">
        <b-card
          v-if="tabIndex === index && getLibroSelected"
          aria-hidden="false"
        >
          <asistencias v-if="tabIndex === index"/>
        </b-card>
      </div>
      <div v-if="item.modulo === 'contenidos' && item.can">
        <b-card
          v-if="tabIndex === index && getLibroSelected"
          aria-hidden="false"
        >
          <contenidos v-if="tabIndex === index"/>
        </b-card>
      </div>
      <div v-if="item.modulo === 'pie' && item.can">
        <b-card
          v-if="tabIndex === index && getLibroSelected"
          aria-hidden="false"
        >
          <pie v-if="tabIndex === index"/>
        </b-card>
      </div>
      <div v-if="item.modulo === 'hojavida' && item.can">
        <b-card
          v-if="tabIndex === index && getLibroSelected"
          aria-hidden="false"
        >
          <hojavida v-if="tabIndex === index"/>
        </b-card>
      </div>
    </template>

    <b-card v-if="!getLibroSelected">
      <b-alert
        variant="primary"
        show
        class="text-center mt-1 mr-25 ml-25 pb-2 pt-2"
      >
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        ¡Hey! Selecciona el libro de clases que deseas explorar. 📚
      </b-alert>
    </b-card>
  </div>
</template>
<script>

// * Etiquetas
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BTab, BTabs, BCardTitle,
  BCard, BAlert,
} from 'bootstrap-vue'

import { mapGetters, mapActions } from 'vuex'

// Componentes
import colLinea from '../../components/Form/colLinea.vue'
import lista from './Lista/Lista.vue'
import notas from './Notas/Notas.vue'
import notasAcumulativas from './NotasAcumulativas/Notas.vue'
import notasFormativas from './NotasFormativas/Notas.vue'
import pie from './Pie/Pie.vue'
import asistencias from './Asistencias/Asistencias.vue'
import hojavida from './HojaVida/HojaVida.vue'
import contenidos from './ControlContenidos/Contenidos.vue'

export default {
  components: {
    // Etiquetas
    BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback,
    BMedia, BButton, BAvatar, BTab, BTabs, BCardTitle,
    BCard, BAlert,

    // Components
    colLinea,
    lista,
    notas,
    notasAcumulativas,
    notasFormativas,
    pie,
    asistencias,
    hojavida,
    contenidos,
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getConfiguraciones: 'configuraciones/getConfiguraciones',
      getLibroSelected: 'libros/getLibroSelected',
    }),
    disabledList() {
      console.log('screen width >', window.screen.width)
      return window.screen.width < 766
    },
  },
  data() {
    return {
      tabIndex: null,
      configuraciones: {
        sumativa: true,
      },
      tituloMenu: null,
      itemsTabs: [],
      items: [
        {
          id: 1,
          title: 'Lista',
          icon: 'UsersIcon',
          active: true,
          modulo: 'lista',
          can: this.$can('read', 'lista')
        },
        {
          id: 2,
          title: 'Registro de actividades',
          icon: 'LayersIcon',
          active: false,
          modulo: 'contenidos',
          can: this.$can('read', 'contenidos')
        },
        {
          id: 3,
          title: 'Asistencias',
          icon: 'CheckSquareIcon',
          active: false,
          modulo: 'asistencias',
          can: this.$can('read', 'asistencias')
        },
        {
          id: 4,
          title: 'Notas',
          icon: 'EditIcon',
          active: false,
          modulo: 'notas',
          can: this.$can('read', 'notas')
        },
        {
          id: 5,
          title: 'Hoja de vida',
          icon: 'ClipboardIcon',
          active: false,
          modulo: 'hojavida',
          can: this.$can('read', 'hojavida')
        },
        {
          id: 6,
          title: 'PIE',
          icon: 'FileTextIcon',
          active: false,
          modulo: 'pie',
          // can: this.$can('read', 'pie')
          can: false
        },
      ],
    }
  },
  watch: {
    tabIndex(tabIndex) {
      this.tituloMenu = this.itemsTabs[tabIndex].title
    },
  },
  mounted() {
    this.setConfiguraciones()
  },
  methods: {
    ...mapActions({
      fetchConfiguraciones: 'configuraciones/fetchConfiguraciones',
    }),
    setConfiguraciones() {
      this.fetchConfiguraciones(this.getUser.id_establecimiento).then(() => {
        this.setItemsTabs()
      })
    },
    setItemsTabs() {
      let id = 0
      this.items.forEach(item => {
        if (item.can) {
          this.itemsTabs.push({
            id,
            title: item.title,
            icon: item.icon,
            active: item.active,
            modulo: item.modulo,
            can: item.can,
          })

          // chocota conf.sumativa = true
          // cataluña conf.sumativa = true && conf.sumativa_a_nota = true
          if (this.getConfiguraciones.sumativa === 1 && item.modulo === 'notas') {
            id++
            if (this.getConfiguraciones.sumativa_a_nota == false) {
              this.itemsTabs.push({
                id,
                title: 'Notas formativas',
                icon: item.icon,
                active: item.active,
                modulo: 'notasformativas',
                can: item.can,
              })
            }
            if (this.getConfiguraciones.sumativa_a_nota == true) {
              this.itemsTabs.push({
                id,
                title: 'Notas acumulativas',
                icon: item.icon,
                active: item.active,
                modulo: 'notasacumulativas',
                can: item.can,
              })
            }
          }
          id++
        }
      });
    }
  },
}
</script>
