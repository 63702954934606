<template>
  <b-overlay
    v-if="$can('read', 'hojavida')"
    :show="cargando"
    spinner-variant="primary"
    :style="cargando ? 'margin-top: 0px !important; padding-bottom: 700px !important' : ''"
    variant="semi-dark"
  >
    <b-tabs
      v-if="asignaturas.length !== 0"
      v-model="asignaturaIndex"
      class="mb-0"
      style="margin: -10px -10px -26px -10px!important;"
      fills
      :align="alignTabs()"
    >
      <b-tab
        v-for="(asignatura, key) in asignaturas"
        :key="key+1"
        :title="asignatura.nombre"
        :active="key === 0"
        lazy
      >
        <colLinea style="margin-top: -15px;" class="mb-1"/>

        <b-row>
          <b-col
            lg="10"
            md="12"
            sm="12"
          >

          </b-col>

          <!-- CREAR HOJA VIDA -->
          <b-col
            cols="12"
            lg="2"
            md="12"
            sm="12"
          >

            <!-- Crear -->
            <observaciones-create
              :estudiantes.sync="optionsEstudiantes"
              :asignaturas.sync="optionsAsignaturas"
              :tipos.sync="optionsTiposObservacion"
              :asignaturaIndex="asignaturaIndex + 1"
            />

            <!-- btn crear -->
            <btn-crear-modal
              v-if="!cargando"
              accion="Crear"
              modulo="hojavida"
              texto="Observación"
              modal="modal-observacion-create"
            />
          </b-col>

          <!-- TABLA -->
          <b-col cols="12">
            <b-alert
              v-if="bloquesAlert"
              variant="info"
              show
              class="text-center mt-25 mr-25 ml-25 pb-2 pt-2"
            >
              {{alertMsg}}
            </b-alert>
            <b-table
              v-else
              small
              hover
              noCollapse
              bordered
              class="mb-2 rounded"
              :style="cargando ? 'min-height: 25px !important;' : 'min-height: 625px !important;'"
              responsive
              sticky-header
              :fields="!cargando ? fields : null"
              :items="items"
            >
              <!-- Cargando -->
              <template #table-busy>
                <div class="text-center text-danger my-1 mt-5">
                  <spinner />
                </div>
              </template>

              <!-- VER OBSERVACIONES -->
              <template #cell(show_details)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  plain
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                  <span class="vs-label">{{ row.detailsShowing ? 'Cerrar' : 'Mostrar' }}</span>
                </b-form-checkbox>
              </template>

              <!-- TABLE OBSERVACIONES ALUMNO -->
              <template class="m-0 p-0" #row-details="row">
                <b-card class="m-0 p-0">
                  <b-table
                    v-if="row.item.observacionesAlumno.length !== 0"
                    striped
                    hover
                    noCollapse
                    bordered
                    class="rounded p-0 m-0"
                    responsive
                    sticky-header
                    :fields="!cargando ? fieldsObservaciones : null"
                    :items="row.item.observacionesAlumno"
                  >
                    <template #cell(tipo)="data">
                      <b-badge
                        pill
                        :variant="data.item.tipoObservacion.variant"
                      >
                        {{ data.item.tipoObservacion.nombre }}
                      </b-badge>
                    </template>

                    <!-- col: ACCIONES -->
                    <template #cell(acciones)="data">
                      <!-- EDITAR -->
                      <observaciones-update
                        :estudiantes.sync="optionsEstudiantes"
                        :asignaturas.sync="optionsAsignaturas"
                        :tipos.sync="optionsTiposObservacion"
                        :asignaturaIndex="asignaturaIndex + 1"
                        :nombremodal="`modal-observacion-${data.item.id}`"
                        :data="data.item"
                      />
                      <colAccionesBtnes
                        modulo="hojavida"
                        :modal="`modal-observacion-${data.item.id}`"
                        :data="data"
                        @processRemove="remove(data.item)"
                      />
                    </template>
                  </b-table>
                </b-card>
              </template>

              <template #cell(negativas)="data">
                <b-badge
                  pill
                  variant="light-danger"
                >
                  {{ data.item.negativas }}
                </b-badge>
              </template>

              <template #cell(positivas)="data">
                <b-badge
                  pill
                  variant="light-success"
                >
                  {{ data.item.positivas }}
                </b-badge>
              </template>

              <template #cell(faltas)="data">
                <b-badge
                  pill
                  variant="light-warning"
                >
                  {{ data.item.faltas }}
                </b-badge>
              </template>

              <template #cell(otros)="data">
                <b-badge
                  pill
                  variant="light-secondary"
                >
                  {{ data.item.otros }}
                </b-badge>
              </template>

            </b-table>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>

    <b-row
      v-else-if="!cargando"
    >

      <b-col
        cols="12"
        md="3"
        class="mt-2"
      ></b-col>
      <b-col
        class="mt-2"
        md="6"
      >
        <b-alert
          show
          variant="primary"
          style="margin-top: -5px"
        >
          <!-- ADMIN, DIRECTOR, JEFE UTP -->
          <div
            v-if="getUser.id_rol === 1 || getUser.id_rol === 3 || getUser.id_rol === 4"
            class="alert-body text-center mt-25"
          >
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            Ningún docente tiene asignaturas asignadas en este curso.<br>
            Por favor, asegúrese que los docentes ingresen su carga horaria.
          </div>
          <!-- DOCENTES, EDUCADORA DIFERENCIAL, PROFESIONAL DE LA SALUD  -->
          <div
            v-else-if="getUser.id_rol === 2 || getUser.id_rol === 6 || getUser.id_rol === 7"
            class="alert-body text-center mt-25"
          >
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            La cuenta no posee asignaturas asignadas en este curso.<br>
            Por favor, ingrese su horario en <b-link :to="{ name: 'mis-horarios'}"><u>Mis Horarios</u></b-link>.
          </div>

        </b-alert>
      </b-col>
    </b-row>

  </b-overlay>
</template>

<script>

// Etiquetas //
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BTab, BTabs, BTable,
  BFormCheckbox, BCard, BBadge, BAlert, BLink
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { mapGetters, mapActions } from 'vuex'

// FORMATOS
import { formatos } from '@core/mixins/ui/formatos'

// COMPONENTS RECICLADOS//
import btnCrearModal from '../../../components/List/btnCrearModal.vue'
import inputFiltro from '../../../components/List/inputFiltro.vue'
import btnMostrar from '../../../components/List/btnMostrar.vue'
import colAccionesBtnes from '../../../components/List/colAccionesBtnes.vue'
import colPeriodo from '../../../components/List/colPeriodo.vue'
import colEstado from '../../../components/List/colEstado.vue'
import spinner from '../../../components/spinner.vue'
import colNombreImg from '../../../components/List/colNombreImg.vue'
import colLinea from '../../../components/Form/colLinea.vue'

// COMPONENTES HIJO
import ObservacionesCreate from './Observaciones/ObservacionesCreate.vue'
import ObservacionesUpdate from './Observaciones/ObservacionesUpdate.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    BOverlay,
    BTab,
    BTabs,
    BTable,
    BFormCheckbox,
    BCard,
    BBadge,
    BAlert,
    BLink,
    vSelect,

    // COMPONENTES RECICLADOS
    colAccionesBtnes,
    btnCrearModal,
    inputFiltro,
    btnMostrar,
    colPeriodo,
    colEstado,
    spinner,
    colNombreImg,
    colLinea,

    // COMPONENTES HIJO
    ObservacionesCreate,
    ObservacionesUpdate,
  },
  data() {
    return {
      cargando: true,
      asignaturasFilter: null,
      estudiantesFilter: null,
      asignaturaIndex: null,

      bloquesAlert: false,
      alertMsg: 'El curso no tiene Estudiantes Matriculados.',
      // Align Menu
      pantallaWidth: window.innerWidth,
      sizesWidth: [
        {
          id: 'sm',
          min: 200,
          max: 575,
        },
        {
          id: 'md',
          min: 575,
          max: 992,
        },
        {
          id: 'lg',
          min: 992,
          max: 4900,
        },
      ],

      perPage: 100,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [50, 100, 200],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },

      asignaturas: [],
      optionsEstudiantes: [],
      optionsAsignaturas: [],
      optionsTiposObservacion:[
        {
          id: 1,
          nombre: 'Anotación negativa',
          variant: 'light-danger',
        },
        {
          id: 2,
          nombre: 'Anotación positiva',
          variant: 'light-success',
        },
        {
          id: 3,
          nombre: 'Falta',
          variant: 'light-warning',
        },
        {
          id: 4,
          nombre: 'Otros',
          variant: 'light-secondary',
        }
      ],

      items: [],
      fieldsObservaciones: [
        // {
        //   key: 'asignatura',
        //   label: 'Asignatura',
        //   // sortable: true,
        //   variant: 'light',
        //   thStyle: {
        //     width: '20% !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
        {
          key: 'fechaformat',
          label: 'Fecha',
          // sortable: true,
          variant: 'light',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '14% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'tipo',
          label: 'Tipo',
          // sortable: true,
          variant: 'light',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '6% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'observacion',
          label: 'Observaciones',
          // sortable: true,
          variant: 'light',
          thStyle: {
            width: '60% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fields: [
        {
          key: 'show_details',
          label: 'Ver Hoja de Vida',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '2% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'orden',
          label: '#',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '2% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre_estudiante',
          label: 'Estudiantes',
          // sortable: true,
          stickyColumn: true,
          thStyle: {
            width: '20% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'negativas',
          label: 'Negativas',
          // sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '4% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'positivas',
          label: 'Positivas',
          // sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '4% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'faltas',
          label: 'Faltas',
          // sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '4% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'otros',
          label: 'Otros',
          // sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '4% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '5% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  mixins: [formatos],
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getLibroSelected: 'libros/getLibroSelected',
      getHorarios: 'horarios/getHorarios',
      getObservacionesAsignatura: 'observaciones/getObservacionesAsignatura',
    }),
  },
  watch: {
    getLibroSelected(val) {
      this.cargando = true
      this.cargaAsignaturas(this.getLibroSelected.id)
    },
    estudiantesFilter(estudiante) {
      cargarObservaciones(estudiante)
    },
    getObservacionesAsignatura(estudiantes) {
      if (estudiantes.message !== 'No Existen Alumnos') {
        this.cargaEstudiantesObservaciones(estudiantes)
      } else {
        this.bloquesAlert = true
      }
    },
    asignaturaIndex(asignaturaIndex) {
      this.cargaObservacionesAsignatura(this.asignaturas[asignaturaIndex].id)
    },
  },
  mounted() {
    this.cargaAsignaturas(this.getLibroSelected.id)
    this.setTableList()
    // Align Menu
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResizePantalla);
    })
  },
  methods: {
    ...mapActions({
      fetchHorarioUsuarioCurso: 'horarios/fetchHorarioUsuarioCurso',
      fetchObservacionesAsignatura: 'observaciones/fetchObservacionesAsignatura',
      removeObservacion: 'observaciones/removeObservacion',
    }),
    setTableList() {
      if (this.$can('update', 'hojavida')
        || this.$can('delete', 'hojavida')
      ) {
        this.fieldsObservaciones.push(this.fieldAcciones)
      }
    },
    cargaAsignaturas() {
      this.fetchHorarioUsuarioCurso(this.getLibroSelected.id).then(() => {
        this.optionsAsignaturas = []
        this.asignaturas = []
        this.getHorarios.forEach(horario => {
          const findAsignatura = this.optionsAsignaturas.filter(as => as.id === horario.id_asignatura)
          if (findAsignatura.length === 0) {
            this.optionsAsignaturas.push({
              id_curso: horario.id_curso,
              id: horario.id_asignatura,
              nombre: horario.nombre_asignatura,
            })
            this.asignaturas.push({
              id_curso: horario.id_curso,
              id: horario.id_asignatura,
              nombre: horario.nombre_asignatura,
            })
          }
        })
      })
    },
    cargaObservacionesAsignatura(id_asignatura) {
      this.fetchObservacionesAsignatura(id_asignatura).then(() => {
        this.cargando = false
      })
    },
    cargaEstudiantesObservaciones(estudiantes) {

      this.items = []
      this.optionsEstudiantes = []
      estudiantes.forEach(alumno => {
          const nombre = alumno.orden+' - '+alumno.primer_apellido + ' ' +alumno.segundo_apellido+' '+alumno.nombre + ' ' +alumno.segundo_nombre
          this.optionsEstudiantes.push({
            id_persona_rol_estudiante: alumno.id_persona_rol_alumno,
            nombre
          })

          const nombre_estudiante = alumno.primer_apellido + ' ' +alumno.segundo_apellido+' '+alumno.nombre + ' ' +alumno.segundo_nombre
          let observacionesAlumno = []
          if (alumno.observaciones.length > 0) {
            alumno.observaciones.forEach(observacion => {
              const tipoObservacion = this.optionsTiposObservacion.find(oto => oto.id == observacion.tipo)
              const fecha = `${this.formatFechaVer(observacion.fecha)}`
              observacionesAlumno.push({
                id: observacion.id,
                fechaformat: fecha,
                fecha: observacion.fecha,
                id_persona_rol_estudiante: observacion.id_persona_rol_estudiante,
                tipoObservacion: tipoObservacion,
                id_asignatura: observacion.id_asignatura,
                tipo: observacion.tipo,
                observacion: observacion.observacion,
              })
            })
          }

          const numNegativas = alumno.observaciones.filter(oto => oto.tipo == 1)
          const numPositivas = alumno.observaciones.filter(oto => oto.tipo == 2)
          const numFaltas = alumno.observaciones.filter(oto => oto.tipo == 3)
          const numOtros = alumno.observaciones.filter(oto => oto.tipo == 4)

          this.items.push({
            id_persona_rol_estudiante: alumno.id_persona_rol_alumno,
            orden: alumno.orden,
            nombre_estudiante,
            negativas: numNegativas.length,
            positivas: numPositivas.length,
            faltas: numFaltas.length,
            otros: numOtros.length,
            observacionesAlumno,
          })
        })
    },
    remove(observacion) {
      this.$swal({
        title: 'Eliminar observación!',
        text: `Estás seguro que deseas eliminar la observación?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminala!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          if (observacion.id >= 0) {
            const data = {
              id: observacion.id,
              id_asignatura: observacion.id_asignatura,
            }
            this.removeObservacion(data).then(() => {
              this.$swal({
                icon: 'success',
                title: 'Eliminada con éxito!',
                text: `La observación ha sido eliminada!`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          }
          this.spinner = false
        } else {
          this.spinner = false
        }
      })
    },

    // Align Menu
    onResizePantalla() {
      this.pantallaWidth = window.innerWidth
      this.alignTabs()
    },
    alignTabs() {
      let align
      this.sizesWidth.forEach(size => {
        if (this.pantallaWidth >= size.min && this.pantallaWidth <= size.max) {
          if (size.id === 'sm') {
            align = 'center'
          } else if (size.id === 'md') {
            align = 'center'
          } else if (size.id === 'lg') {
            align = 'left'
          }
        }
      })
      return align
    },
  },
  // Align Menu
  beforeDestroy() {
    window.removeEventListener('resize', this.onResizePantalla);
  },
}
</script>
