var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.nombreModal,"title":_vm.title,"centered":"","size":"lg","cancel-title":"Cancelar","cancel-variant":"outline-secondary","ok-title":"Guardar Estrategia","ok-disabled":this.v$.estrategia.$errors.length > 0},on:{"ok":function($event){$event.preventDefault();return _vm.submitOption($event)}}},[_c('b-overlay',{attrs:{"show":_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Estrategia *","label-for":"estrategia"}},[_c('b-form-textarea',{attrs:{"id":"estrategia","placeholder":"Ingresa la estrategia","rows":"2","state":_vm.v$.estrategia.estrategia.$error === true
                ? false
                : null},nativeOn:{"blur":function($event){return _vm.v$.estrategia.estrategia.$touch($event)}},model:{value:(_vm.estrategia.estrategia),callback:function ($$v) {_vm.$set(_vm.estrategia, "estrategia", $$v)},expression:"estrategia.estrategia"}}),(_vm.v$.estrategia.estrategia.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"estrategiaInfo"}},_vm._l((_vm.v$.estrategia.estrategia.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Ámbito o asignatura donde se aplicará *","label-for":"id_asignatura"}},[_c('v-select',{class:_vm.v$.estrategia.id_asignatura.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"placeholder":"Selecciona una asignatura...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"texto","options":_vm.asignaturasOption,"reduce":function (option) { return option.id; },"input-id":"id_periodo"},model:{value:(_vm.estrategia.id_asignatura),callback:function ($$v) {_vm.$set(_vm.estrategia, "id_asignatura", $$v)},expression:"estrategia.id_asignatura"}}),(_vm.v$.estrategia.id_asignatura.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_asignaturaInfo"}},_vm._l((_vm.v$.estrategia.id_asignatura.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Período de tiempo en que se aplicará *","label-for":"periodo"}},[_c('v-select',{class:_vm.v$.estrategia.id_periodo.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"placeholder":"Selecciona un periodo...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"texto","options":_vm.periodosOption,"reduce":function (option) { return option.id; },"input-id":"id_periodo"},model:{value:(_vm.estrategia.id_periodo),callback:function ($$v) {_vm.$set(_vm.estrategia, "id_periodo", $$v)},expression:"estrategia.id_periodo"}}),(_vm.v$.estrategia.id_periodo.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_periodoInfo"}},_vm._l((_vm.v$.estrategia.id_periodo.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('popover',{staticClass:"float-right",staticStyle:{"margin-right":"-4px","margin-top":"-13px","margin-bottom":"-8px"},attrs:{"id":"0","btnVariant":"flat-secondary","texto":_vm.informacion,"direccion":"right"}}),_c('b-form-group',{attrs:{"label":"Criterios para la evaluación de la estrategia *","label-for":"criterios"}},[_c('b-form-textarea',{attrs:{"id":"criterios","placeholder":"Ingresa los criterios","rows":"2","state":_vm.v$.estrategia.criterios.$error === true
                ? false
                : null},nativeOn:{"blur":function($event){return _vm.v$.estrategia.criterios.$touch($event)}},model:{value:(_vm.estrategia.criterios),callback:function ($$v) {_vm.$set(_vm.estrategia, "criterios", $$v)},expression:"estrategia.criterios"}}),(_vm.v$.estrategia.criterios.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"criteriosInfo"}},_vm._l((_vm.v$.estrategia.criterios.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }