var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('b-form',{ref:"form",staticClass:"mb-3"},[_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Evaluación de proceso y avance","label-for":"proceso"}},[_c('b-form-textarea',{attrs:{"id":"proceso","placeholder":_vm.$can('update', _vm.nombre_permiso)
                  ? "Indique estrategias y procedimientos que aplicará para las siguientes evaluaciones:\n- Evaluaciones para el aprendizaje, periódicas, se recomiendan quincenales.\n- Evaluaciones de resultados; trimestrales, semestrales con informe escrito a la Familia."
                  : 'Sin información',"plaintext":!_vm.$can('update', _vm.nombre_permiso),"rows":"4","state":_vm.v$.evaluacion.proceso.$error === true
                ? false
                : null},nativeOn:{"blur":function($event){return _vm.v$.evaluacion.proceso.$touch($event)}},model:{value:(_vm.evaluacion.proceso),callback:function ($$v) {_vm.$set(_vm.evaluacion, "proceso", $$v)},expression:"evaluacion.proceso"}}),(_vm.v$.evaluacion.proceso.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"evaluacionInfo"}},_vm._l((_vm.v$.evaluacion.proceso.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Evaluación anual de logros de aprendizaje; de evolución\n            del déficit o trastorno, de egreso o de continuidad en el PIE","label-for":"evaluacionAnual"}},[_c('b-form-textarea',{attrs:{"id":"evaluacionAnual","placeholder":_vm.$can('update', _vm.nombre_permiso)
                ? "Indique estrategias y procedimientos que aplicará para las siguientes evaluaciones:\n- Evaluaciones para el aprendizaje, periódicas, se recomiendan quincenales.\n- Evaluaciones de resultados; trimestrales, semestrales con informe escrito a la Familia."
                : 'Sin información',"plaintext":!_vm.$can('update', _vm.nombre_permiso),"rows":"4","state":_vm.v$.evaluacion.evaluacionAnual.$error === true
                ? false
                : null},nativeOn:{"blur":function($event){return _vm.v$.evaluacion.evaluacionAnual.$touch($event)}},model:{value:(_vm.evaluacion.evaluacionAnual),callback:function ($$v) {_vm.$set(_vm.evaluacion, "evaluacionAnual", $$v)},expression:"evaluacion.evaluacionAnual"}}),(_vm.v$.evaluacion.evaluacionAnual.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"evaluacionAnualInfo"}},_vm._l((_vm.v$.evaluacion.evaluacionAnual.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Observaciones","label-for":"observaciones"}},[_c('b-form-textarea',{attrs:{"id":"observaciones","placeholder":"Indique las observaciones","placeholder":_vm.$can('update', _vm.nombre_permiso)
                ? 'Indique las observaciones'
                : 'Sin información',"plaintext":!_vm.$can('update', _vm.nombre_permiso),"rows":"4","state":_vm.v$.evaluacion.observaciones.$error === true
                ? false
                : null},nativeOn:{"blur":function($event){return _vm.v$.evaluacion.observaciones.$touch($event)}},model:{value:(_vm.evaluacion.observaciones),callback:function ($$v) {_vm.$set(_vm.evaluacion, "observaciones", $$v)},expression:"evaluacion.observaciones"}}),(_vm.v$.evaluacion.observaciones.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"observacionesInfo"}},_vm._l((_vm.v$.evaluacion.observaciones.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('b-row',{ref:"row"},[_c('b-col',{attrs:{"md":"8"}}),_c('b-col',{attrs:{"md":"4"}},[_c('btnSubmit',{staticClass:"float-right",attrs:{"variant":"primary","modulo":_vm.nombre_permiso,"disabled":this.v$.evaluacion.$errors.length > 0,"btnText":_vm.btnSubmit},on:{"processBtn":_vm.submitOption}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }