<template>
  <div class="d-flex justify-content-center">
    <div v-if="$can('firmar', modulo)">
      <b-modal
        :id="'modal-firma-'+idModal"
        :title="title"
        :hide-footer="true"
        size="md"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        centered
      >
        <b-overlay
          :show="cargando"
          spinner-variant="primary"
          variant="semi-dark"
        >
          <!-- field: CODIGO -->
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mt-1"
            >
              <!-- label="Firma digital" -->
              <b-form-group
                label-for="codigo"
              >
                <b-form-input
                  id="codigo"
                  autofocus
                  v-model="firma.codigo"
                  placeholder="Ingresa tu firma digital"
                  :state="v$.firma.codigo.$error === true
                    ? false
                    : null"
                  @keyup="changeCodigo"
                />
                  <!-- @blur.native="v$.firma.codigo.$touch" -->
                  <!-- @blur="!evaluacionDisabled ? cambiaNota() : ''" -->
                <b-form-invalid-feedback
                  v-if="v$.firma.codigo.$error"
                  id="codigoInfo"
                  class="text-right"
                >
                  <p v-for="error of v$.firma.codigo.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </b-form-invalid-feedback>
            <!-- <b-col
              cols="3"
              md="2"
              xs="4"
              class="mt-75"
            >
              <b-button
                variant="outline-secondary"
                class="mr-0 mr-md-1 btn-sm-block mt-25 mb-25"
                title="Pegar código"
                @click="copiarCodigo"
              >
                <feather-icon
                  icon="CopyIcon"
                />
                <!-- ClipboardIcon -->
              <!-- </b-button> -->
              </b-form-group>
            </b-col>
          </b-row>
        </b-overlay>
        <colLinea style="margin-top: 15px; margin-bottom: 15px;"/>
        <b-row>
          <b-col
            cols="12"
            md="12"
            sm="12"
            class="text-right"
          >
            <b-button
              variant="outline-secondary"
              class="mr-0 mr-md-1 btn-sm-block mt-25 mb-25"
              :disabled="cargando"
              @click="cancelOption()"
            >
              Cancelar
            </b-button>
            <b-button
              variant="success"
              class="mr-0 btn-sm-block mt-25 mb-25"
              :disabled="cargando"
              @click="submitOption()"
            >
              Firmar
              <feather-icon
                icon="FeatherIcon"

              />
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BBadge, BFormGroup, BFormInvalidFeedback, BFormInput,
  BOverlay,
} from 'bootstrap-vue'

import { formatos } from '@core/mixins/ui/formatos'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, minLength } from '@vuelidate/validators'

// VUEX
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { useClipboard, useShare } from '@vueuse/core'


// COMPONENTES RECICLADOS
import colLinea from '../Form/colLinea.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BBadge,
    BFormInvalidFeedback,
    BFormInput,
    BOverlay,
    // COMPONENTES RECICLADOS
    colLinea,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mixins: [formatos],
  data() {
    return {
      cargando: false,
      firma: {
        codigo: null,
      },
    }
  },
  validations() {
    return {
      firma: {
        codigo: {
          required: helpers.withMessage('El código es requerido.', required),
          minLength: helpers.withMessage('El código de firma digital, debe tener un mínimo de 6 caracteres.', minLength(6)),
          maxLength: helpers.withMessage('El código de firma digital, debe tener un máximo de 6 caracteres.', maxLength(6)),
        },
      }
    }
  },
  watch: {
    cargar (val) {
      if (!val) {
        this.cargando = false
        this.firma.codigo = null
      }
    },
  },
  props: {
    idModal: {
      type: Number,
      default: 1,
    },
    modulo: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    cargar: {
      type: Boolean,
      required: false,
    }
  },
  methods: {
    submitOption() {
      this.v$.firma.$touch()
      if (!this.v$.firma.$invalid) {
        this.cargando = true
        this.$emit('processFirma', this.firma.codigo)
        this.v$.firma.$reset()
      }
    },
    cancelOption() {
      this.$bvModal.hide('modal-firma-'+this.idModal)
      this.v$.firma.$reset()
      this.cargando = false
      this.$emit('processCancel')
    },
    changeCodigo() {
      this.firma.codigo = this.formatCodigoFirma(this.firma.codigo)
      if (event.which === 13) {
        this.submitOption()
      }
    },
    copiarCodigo(event) {
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>
