var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.nombreModal,"title":_vm.title,"centered":"","size":"lg","cancel-title":"Cancelar","cancel-variant":"outline-secondary","ok-title":"Guardar Acción","ok-disabled":this.v$.aplicacion.$errors.length > 0},on:{"ok":function($event){$event.preventDefault();return _vm.submitOption($event)}}},[_c('b-overlay',{attrs:{"show":!_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Estrategia (Periodo) *","label-for":"estrategia"}},[_c('v-select',{class:_vm.v$.aplicacion.id_detalle_estrategia.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"placeholder":_vm.estrategiasDisponiblesOption.length === 0
                ? 'No hay estrategias disponibles...'
                : 'Selecciona una estrategia...',"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"estrategia","options":_vm.estrategiasDisponiblesOption,"reduce":function (option) { return option.id; },"input-id":"estrategia","state":_vm.v$.aplicacion.id_detalle_estrategia.$error === true
              ? false
              : null,"disabled":_vm.estrategiasDisponiblesOption.length === 0},nativeOn:{"blur":function($event){return _vm.v$.aplicacion.id_detalle_estrategia.$touch($event)}},model:{value:(_vm.aplicacion.id_detalle_estrategia),callback:function ($$v) {_vm.$set(_vm.aplicacion, "id_detalle_estrategia", $$v)},expression:"aplicacion.id_detalle_estrategia"}}),(_vm.estrategiasDisponiblesOption.length === 0)?_c('b-alert',{staticClass:"pl-50 pt-25 pb-25 pr-50 mt-50 mb-25",attrs:{"variant":"primary","show":""}},[_vm._v(" Para crear una nueva estrategia, dirígase al menú \"II 1 b\". ")]):_vm._e(),(_vm.v$.aplicacion.id_detalle_estrategia.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"asistentesInfo"}},_vm._l((_vm.v$.aplicacion.id_detalle_estrategia.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Acción desarrollada*","label-for":"desarrollo"}},[_c('b-form-textarea',{attrs:{"id":"desarrollo","placeholder":"Ingresa la acción desarrollada","rows":"2","state":_vm.v$.aplicacion.acciones.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.aplicacion.acciones.$touch($event)}},model:{value:(_vm.aplicacion.acciones),callback:function ($$v) {_vm.$set(_vm.aplicacion, "acciones", $$v)},expression:"aplicacion.acciones"}}),(_vm.v$.aplicacion.acciones.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"accionInfo"}},_vm._l((_vm.v$.aplicacion.acciones.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Evaluación (resultados) de las estrategias aplicadas *","label-for":"evaluacion"}},[_c('b-form-textarea',{attrs:{"id":"evaluacion","placeholder":"Ingresa la evaluación","state":_vm.v$.aplicacion.evaluacion.$error === true
                ? false
                : null},nativeOn:{"blur":function($event){return _vm.v$.aplicacion.evaluacion.$touch($event)}},model:{value:(_vm.aplicacion.evaluacion),callback:function ($$v) {_vm.$set(_vm.aplicacion, "evaluacion", $$v)},expression:"aplicacion.evaluacion"}}),(_vm.v$.aplicacion.evaluacion.$error)?_c('b-form-invalid-feedback',{staticClass:"text-right",attrs:{"id":"evaluacionInfo"}},_vm._l((_vm.v$.aplicacion.evaluacion.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }