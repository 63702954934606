<template>
  <div>
    <b-button
      v-if="!disabled"
      variant="relief-primary"
      class="mr-0 btn-sm mt-25 ml-0 mr-md-0 mr-50"
      :block="true"
      title="Guardar"
      @click="submit('Guardar')"
    >
      <span class="d-inline d-md-none mr-25">
        Guardar
      </span>
      <feather-icon
        icon="SaveIcon"
      />
    </b-button>
    <b-button
      v-if="!disabled && $can('firmar', 'contenidos')"
      variant="relief-success ml-0"
      class="mr-0 btn-sm mt-25"
      :block="true"
      title="Firmar"
      @click="submit('Firmar')"
    >
      <span class="d-inline d-md-none d-sm-inline mr-25">
        Firmar
      </span>
      <feather-icon
        icon="FeatherIcon"
      />
    </b-button>

  </div>
</template>

<script>
import {
  BButton, BBadge,
} from 'bootstrap-vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
    BBadge,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
    }),
  },
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    submit(boton) {
      const data = {
        contenido: this.item,
        boton,
      }
      this.$emit('processBtn', data)
    },
  },
}
</script>
