<template>
  <div
    :class="`text-center`"
    style="
          margin-top: -4px; padding-top: 5px;
          margin-left: -8px; padding-left: 8px;
          margin-right: -8px; padding-right: 8px;
          margin-bottom: -5px; padding-bottom: 5px;
          min-width: 130px !important;
        "
  >
    <spinner
      v-if="showSpinner"
      size="small"
    />
    <b-row>
      <b-col
        cols="12"
        lg="11"
        md="9"
        sm="12"
      >
      <!-- {{ classDiv }} -->
        <b-form-textarea
          v-model="contenido"
          id="contenido"
          :placeholder="!disabled
            ? 'Ingresa la actividad de la clase'
            : ''"
          :disabled="disabled"
          :class="`overflow-auto ${classTextarea}`"
          :aria-invalid="false"
          icon="CheckIcon"
          max-rows="8"
        />
          <!-- :title="titleTooltip" -->
        <div
          :id="`detalle-actividad-${item.id}`"
          :title="titleTooltip"
          :class="`icon-textarea ${iconColor}`"
        >
          <!-- <span class="d-none d-sm-none d-xs-none d-md-inline d-lg-inline text-secondary"> -->
          <small class="d-none d-sm-none d-xs-none d-md-inline d-lg-inline">
            {{ detalleTextarea }}
          </small>
          <!-- </small> -->
          <b-overlay
            :show="cargandoSubmit"
            opacity="0.10"
            spinner-small
            spinner-type="grow"
            class="d-inline"
          >
            <feather-icon
              :class="`${iconColor}`"
              :icon="iconTextarea"
            />
          </b-overlay>
        </div>
        <b-tooltip
          :target="`detalle-actividad-${item.id}`"
          :variant="iconColor === 'text-primary' ? 'primary' : 'success'"
        >
          {{titleTooltip}}
        </b-tooltip>

        <div
          v-if="messageError !== null"
          id="contenidoInfo"
          class="text-danger text-right"
          style="font-size: 0.857rem;"
        >
          <p>
            {{ messageError }}
          </p>
        </div>
      </b-col>
      <b-col
        cols="12"
        lg="1"
        md="2"
        sm="12"
        class="text-center"
      >
        <btn-guardar-sin-firmar
          class="btn-guardar"
          :id="item.id"
          :item="item"
          :disabled="disabled"
          @processBtn="submit"
        />
        <modal-firma
          v-if="!disabled"
          :idModal="item.id"
          :cargar.sync="cargarFirma"
          modulo="contenidos"
          title="Firmar registro de actividad"
          @processFirma="firmar"
          @processCancel="cargandoSubmit = false"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormCheckbox, VBTooltip, BFormSelect, BCol, BRow, BFormTextarea, BOverlay,
  BTooltip,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// FORMATOS
import { formatos } from '@core/mixins/ui/formatos'

// COMPONENTES RECICLADOS
import spinner from '../../../../components/spinner.vue'
import modalFirma from '../../../../components/Firma/modalFirma.vue'

// COMPONENTES HIJOS
import btnGuardarSinFirmar from './components/btnGuardarSinFirmar.vue'

export default {
  components: {
    BFormCheckbox,
    BFormSelect,
    BCol,
    BRow,
    BFormTextarea,
    BOverlay,
    BTooltip,

    // COMPONENTES RECICLADOS
    spinner,
    modalFirma,

    // COMPONENTES HIJOS
    btnGuardarSinFirmar,
  },
  data() {
    return {
      // CARGAR
      cargandoSubmit: false,
      classTextarea: '',
      iconTextarea: '',
      titleTooltip: '',
      detalleTextarea: '',
      iconColor: '',

      cargarFirma: false,
      messageError: null,

      id_bloque: null,
      id_fecha_curso: null,

      classDiv: null,
      contenido: null,
      showSpinne: false,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    // Ripple,
  },
  mixins: [formatos],
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    fechaSelected: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getLibroSelected: 'libros/getLibroSelected',
      getResponseFirma: 'firmas/getResponse',
    }),
    disabled() {
      let response = false
      if (this.item.id_persona_rol !== this.getUser.id_persona_rol) {
        response = true
      }
      if (this.item.estado_firma === 1) {
        response = true
      }
      return response
    },
    showSpinner() {
      return false
    },
  },
  watch: {
    item(item) {
      this.cargaContenido(item)
    },
  },
  mounted() {
    this.cargaContenido(this.item)

  },
  methods: {
    ...mapActions({
      addContenido: 'contenidos/addContenido',
      updateContenido: 'contenidos/updateContenido',
      fetchContenidos: 'contenidos/fetchContenidos',
      addFirmaContenido: 'firmas/addFirmaContenido',
    }),
    mensajeFirma(data) {
      const {
        title,
        icon,
        text,
        variant,
        tipo,
      } = data

      if (tipo === 'toast') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            text,
            icon,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout: 4000,
        })
      } else if (tipo === 'swal') {
        this.$swal({
            title,
            text,
            icon,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
      }

    },
    firmar(codigo) {
      this.detalleTextarea = 'Firmando...'
      this.cargandoSubmit = true
      this.cargarFirma = true
      let response
      let data = {
        id_bloque: this.id_bloque,
        id_fecha_curso: this.id_fecha_curso,
        token: codigo,
      }

      this.addFirmaContenido(data).then(() => {
        let title
        let text
        let variant
        let icon
        let estado_firma
        if (this.getResponseFirma.message === 'Registro Guardado. Estado Firma: Valida') {
          estado_firma = 1
          const data = {
            title: 'Firma válida 👍',
            text: 'La firma ingresada, ha sido validada con éxito!',
            variant: 'success',
            icon: 'CheckIcon',
            estado_firma,
            tipo: 'toast'
          }
          this.mensajeFirma(data)
          this.detalleTextarea = 'Firmado'
        } else if (this.getResponseFirma.message === 'Registro Guardado. Estado Firma: No valida') {
          estado_firma = 0
           const data = {
            title: 'Firma no válida 🚨',
            text: 'La firma ingresada se ha registrado, pero no ha sido validada con éxito!',
            variant: 'warning',
            icon: 'error',
            estado_firma,
            tipo: 'swal'
          }
          this.mensajeFirma(data)
        } else if (this.getResponseFirma.message === 'Token No Valido') {
          title = 'Firma no registrada'
          text = 'La firma ingresada no se ha registrado!'
          variant = 'danger'
          icon = 'ErrorIcon'
          estado_firma = 2
           const data = {
            title: 'Clave incorrecta',
            text: 'La clave ingresada no corresponde a la clave de Authy!',
            variant: 'warning',
            icon: 'error',
            estado_firma,
            tipo: 'swal'
          }
          this.mensajeFirma(data)
        } else if (this.getResponseFirma.message === 'Rut No Existe') {
          estado_firma = 3
           const data = {
            title: 'Rut no habilitado',
            text: 'Su rut aún no tiene la Firma Digital habilitada!',
            variant: 'warning',
            icon: 'error',
            estado_firma,
            tipo: 'swal'
          }
          this.mensajeFirma(data)
        } else if (this.getResponseFirma.message === 'Error conexion Api') {
          estado_firma = null
           const data = {
            title: 'Error de conexión',
            text: 'Intentelo de nuevo más tarde!',
            variant: 'warning',
            icon: 'error',
            estado_firma,
            tipo: 'swal'
          }
          this.mensajeFirma(data)
        }

        this.cargarFirma = false
        this.$bvModal.hide('modal-firma-'+this.id_bloque)
        this.pintarBloque(this.id_bloque, estado_firma)
        this.cargandoSubmit = false
      })
    },
    cargaContenido(contenido) {
      this.classDiv = null
      this.id_bloque = this.id_bloque == null ? this.item.id : this.id_bloque
      this.id_fecha_curso = this.id_fecha_curso == null ? this.item.id_fecha_curso : this.id_fecha_curso
      if (contenido.contenido !== null) {
        this.contenido = contenido.contenido
        this.pintarBloque(contenido.id, contenido.estado_firma)
      } else {
        this.resetStyle()
        this.contenido = null
      }
    },
    cargarContenidos() {
      const data = {
        id_curso: this.getLibroSelected.id,
        fecha: this.fechaSelected,
      }
      this.fetchContenidos(data)
    },
    submit(data) {
      this.cargandoSubmit = true
      this.messageError = null
      if (this.contenido === null) {
        this.cargandoSubmit = false
        this.messageError = 'Debe ingresar el registro de actividad'
      } else {

        this.detalleTextarea = 'Guardando'
        if (!this.item.id_contenido) {
          this.crearContenido(data)
        } else {
          if (data.boton === 'Guardar') {
            this.editarContenido(data)
          } else if (data.boton === 'Firmar') {
            this.firmarContenido(data)
          }
        }
      }
    },

    firmarContenido(datos) {
      const {
        contenido,
        boton,
      } = datos
      if (this.item.contenido !== this.contenido) {

        this.$swal({
          title: 'Deseas guardar los cambios antes de firmar?',
          text: "La actividad que deseas firmar ha sido modificada.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, guardar cambios!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.editarContenido(datos)
          } else {
            this.cargandoSubmit = false
            this.messageError = null
          }
        })
        // si no lo quiere guardar, vuelve el contenido al antiguo y muestra modal
      } else {
        this.$bvModal.show('modal-firma-'+contenido.id)
      }
    },

    crearContenido(datos) {
      const {
        contenido,
        boton,
      } = datos
      const data = {
        fecha: this.fechaSelected,
        id_curso: this.getLibroSelected.id,
        contenido: this.contenido,
        id_horario: contenido.id,
      }
      this.addContenido(data).then(() => {
        const statusContenidos = store.state.contenidos.status
        if (statusContenidos === 'success') {
          const id_fecha_curso = store.state.contenidos.id_fecha_curso
          const id_contenido = store.state.contenidos.id_contenido
          let text
          if (boton === 'Guardar') {
            text = `La actividad fue ingresada, ahora puedes firmar digitalmente
 ingresando al botón verde "Firmar"!`
          } else if (boton === 'Firmar') {
            text = `La actividad fue ingresada, ahora puedes firmar digitalmente
 ingresando el código de tu aplicación Authy!`
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Registro de Actividad guardado 👍',
              icon: 'CheckIcon',
              text,
              variant: 'primary',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })

          this.item.contenido = data.contenido
          this.item.id_contenido = id_contenido
          this.id_bloque = contenido.id
          this.id_fecha_curso = id_fecha_curso
          this.pintarBloque(this.id_bloque, contenido.estado_firma)
          if (boton === 'Firmar') {
            this.$bvModal.show('modal-firma-'+contenido.id)
          }
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.contenidos.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }

        setTimeout(() => {
          this.cargandoSubmit = false
          this.detalleTextarea = 'Guardado'
        }, 1000);
      })
    },
    editarContenido(datos) {
      const {
        contenido,
        boton,
      } = datos

      const data = {
        id: contenido.id_contenido,
        contenido: this.contenido,
      }
      this.updateContenido(data).then(() => {
        const statusContenidos = store.state.contenidos.status
        if (statusContenidos === 'success') {
          let text
          if (boton === 'Guardar') {
            text = `El registro de actividad ya está guardado, recuerda que
puedes firmar digitalmente en el botón verde "Firmar"!`
          } else if (boton === 'Firmar') {
            text = `El registro de actividad ya está guardado, recuerda que
puedes firmar digitalmente código de tu aplicación Authy!`
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Registro de Actividad guardado 👍',
              icon: 'CheckIcon',
              text,
              variant: 'primary',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.item.contenido = data.contenido
          this.id_bloque = contenido.id
          if (typeof contenido.id_fecha_curso !== 'undefined') {
            this.id_fecha_curso = contenido.id_fecha_curso
          }
          this.pintarBloque(this.id_bloque, contenido.estado_firma)
          if (boton === 'Firmar') {
            this.$bvModal.show('modal-firma-'+contenido.id)
          }
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.contenidos.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        setTimeout(() => {
          this.cargandoSubmit = false
          this.detalleTextarea = 'Guardado'
        }, 1000);
      })
    },
    pintarBloque(id_bloque, estado_firma) {
      let thClass
      let tdClass

      this.resetStyle()
      if (this.item.id_persona_rol === this.getUser.id_persona_rol) {
        if (this.item.id_contenido !== 'undefined') {
          if (estado_firma !== 1) {
            this.detalleTextarea = 'Guardado'
            this.titleTooltip = 'El registro de actividad está Guardado'
            this.classTextarea = 'border-primary'
            this.iconColor = 'text-primary'
            this.iconTextarea = 'SaveIcon'
          } else if (estado_firma === 1) {
            this.detalleTextarea = 'Firmado'
            this.titleTooltip = 'El registro de actividad está Firmado'
            this.classTextarea = 'border-success'
            this.iconColor = 'text-success'
            this.iconTextarea = 'FeatherIcon'
          }
        }
      } else {
        if (this.item.id_contenido !== 'undefined') {
          if (estado_firma !== 1) {
            this.detalleTextarea = 'Guardado'
            this.titleTooltip = 'El registro de actividad está Guardado'
            this.classTextarea = 'border-primary bg-light-primary'
            this.iconColor = 'text-primary'
            this.iconTextarea = 'SaveIcon'
          } else if (estado_firma === 1) {
            this.detalleTextarea = 'Firmado'
            this.titleTooltip = 'El registro de actividad está Firmado'
            this.classTextarea = 'border-success bg-light-success'
            this.iconColor = 'text-success'
            this.iconTextarea = 'FeatherIcon'
          }
        }
      }
      this.item.estado_firma = estado_firma
    },
    resetStyle() {
      this.detalleTextarea = ''
      this.titleTooltip = ''
      this.classTextarea = ''
      this.iconColor = ''
      this.iconTextarea = ''
    },
  },
}
</script>

<style scoped>

/* pantallas grandes */
@media screen and (min-width: 1490px) {
  .btn-guardar{
    margin-left: -23px !important;
  }
  .icon-textarea {
    position:absolute;
    top: 1%;
    right: 2%;
  }
}
/* notebook y pantallas medianas */
@media only screen and (min-width: 968px) and (max-width: 1490px) {
  .btn-guardar{
    margin-left: -23px !important;
  }
  .icon-textarea {
    position:absolute;
    top: 1%;
    right: 3%;
  }
}

/* tablet y netbook */
@media only screen and (min-width: 767px) and (max-width: 968px) {
  .btn-guardar{
    margin-left: -23px !important;
  }
  .icon-textarea {
    position:absolute;
    top: 1%;
    right: 8%;
  }
}

/* celu grande y tabled */
@media only screen and (min-width: 467px) and (max-width: 766px) {
  .btn-guardar{
    margin-left: 0px !important;
  }
  .icon-textarea {
    position:absolute;
    top: 0%;
    right: 6%;
  }
}

/* Celu */
@media only screen and (min-width: 200px) and (max-width: 467px) {
  .btn-guardar{
    margin-left: 0px !important;
  }
  .icon-textarea {
    position:absolute;
    top: 0%;
    right: 11%;
  }
}
</style>